/*******************************************************************************
 * BOTTOM-BLOCK.CSS
 *******************************************************************************
 */

.bottom-blocks {
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    margin-bottom: 3rem;
    width: 100%;
}

.bottom-block-container {
    min-height: 180px;
    margin-bottom: .5rem;
}

@include breakpoint(middling) {
    .bottom-block-container {
        margin-bottom: 0;
    }
}

.bottom-block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &__heading {
      color: $teal-900;
      font-size: 1.5rem;
    }

    &__body {
      flex-grow: 1;

      &.bottom-block__body--contact::before {
        font-family: chinacapable;
        content: '\e817';
        font-size: 2rem;
      }
    }

    &__buttons {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &.bottom-block--image-button {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        height: 100%;
    }

    &.bottom-block--image-button {
        &.bottom-block--masterclass {
            background-image: url('/assets/img/site/masterclass-cropped-homepage.jpg');
        }

        &.bottom-block--career {
            background-image: url('/assets/img/site/career-people-sq.jpg');
        }
    }

    &.bottom-block--coloured {
        padding: 2.15rem;
        background-color: $white;
        height: 100%;
        text-align: center;
    }
}
