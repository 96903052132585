/*******************************************************************************
 * MEDIA-PANEL.CSS
 *******************************************************************************
 */

.media_panel {

    &.media_panel--inverted {
        position: relative;
        padding-left: 0;
        padding-right: 0;

        .media_panel_minor-section {
            display: none;
        }

        .media-object-section:first-child {
            padding-right: 1.5rem;
        }

        .media_panel_main-section {
            background-color: $white;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        &:not(.media_panel--stacked):not(.media_panel--stacked-block) {
            background-color: $white;
        }

        .media_panel__button-bottom {
            margin-bottom: 0;
        }

    }

    /* Bugfix for IE 11 */
    .row {
        width: 100%;
    }

    .row .media_panel {
        padding: 1.5rem;

        &:first-child {
            margin-bottom: 1.5rem;
        }
    }

    &.media_panel--stacked-block {
        display: block;
    }

    &.media_panel--stacked,
    &.media_panel--stacked-block {
        .media_panel_main-section {
            padding-left: 1.5rem;
        }
    }

    @include breakpoint(medium) {
        &.media_panel--inverted {

            .media_panel_minor-section {
                display: inherit;
            }

            .media_panel_main-section {
                padding-left: 0;
            }

            .media_panel__button-bottom {
                position: absolute;
                bottom: 1.5rem;
            }
        }

        &.media_panel--stacked,
        &.media_panel--stacked-block {
            &:nth-child(1) {
                padding-right: .75rem;
            }

            &:nth-child(2) {
                padding-left: .75rem;
            }

            .media_panel_main-section {
                padding-left: 1.5rem;
            }
        }
    }

}
