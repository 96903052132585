/*******************************************************************************
 * HEADING-SECTION.CSS
 *******************************************************************************
 */

 .heading-section {
    &__major {
        margin-top: 2.4rem;
        border-top: 1px solid $graphite;
        padding-top: 1.2rem;
        margin-bottom: 1.2rem;
    }

    &--entry-full-width {
      color: $aubergine;
      font-size: 1.625rem;
    }
}
