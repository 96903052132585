/*******************************************************************************
 * WATCHANDLEARN.CSS
 *******************************************************************************
 */

.watchandlearn {
    .row {
        justify-content: center;
    }
    position: relative;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    padding: 5rem 2rem 2rem 2rem;
    background-color: $aubergine;

    &__title {
      color: $aubergine;
      font-size: 1.25rem;
      font-family: $body-font-family;
      font-weight: $global-weight-bold;
    }

    p {
        color: $oil;
    }

    &__heading {
        // text-transform: uppercase;
        font-weight: $global-weight-bold;
        color: $white;
        margin-bottom: 1.8rem;
        width: 100%;
        text-align: center;
    }

    &__subheading {
        color: $white;
        font-size: 1rem;
        width: 100%;
        text-align: center;
        margin-bottom: 3.3rem;
    }

    &__sidebar {
        flex: 1 1 0%;
        padding: 1em;
        background-color: $white;

        &.watchandlearn__title {
          font-size: 1rem;
        }

        &--home {
            padding: 0;
        }
    }

    &__media-object {
        margin-bottom: 0;
        width: 100%;
    }

    &__main {
        flex: 2 2 0%;
        background-color: $white;
        margin-bottom: .5rem;

        p {
            font-size: 1.5rem;
        }

        &-body {
            p {
                font-size: 1rem;
            }
        }
    }

    &__button-block {
        margin-left: .5rem;
        margin-right: .5rem;
        text-align: center;
    }

    &__author {
      display: block;
      font-size: .8em;
      line-height: 1.5;
      font-weight: 400;
      color: $black;
    }

    &__sidebar--home {
        .watchandlearn__hero-link {
            margin-bottom: 1.7rem;
        }
    }

    &__hero-link {
      display: block;
    }

    &__hero {
        margin-bottom: 1rem;
        position: relative;
        opacity: 1;

        &.compat-object-fit {
          // Polyfill objectFit for IE11
          // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
          background-size: cover;
          background-position: center center;
          border-bottom-right-radius: $kt-border-bottom-right-radius;

          img {
            opacity: 0;
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          border-bottom-right-radius: 30px;
        }

        &::before {
            content: '\e818';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-family: chinacapable;
            font-weight: $global-weight-bold;
            color: $white;
            text-align: center;
            cursor: pointer;
            z-index: 1;
            text-decoration: none;
            border: 1px solid $white;
            border-radius: 50%;
            padding-top: .5rem;
            margin: auto;
            width: 2.75rem;
            height: 2.75rem;
        }

        &:hover::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $aluminum;
            opacity: .4;
            border-bottom-right-radius: $kt-border-bottom-right-radius;
        }

        &.watchandlearn__hero--full-height {
          margin-bottom: 0;
          height: 100%;
        }

        &.watchandlearn__hero--full-height img {
          border-bottom-right-radius: inherit;
          height: 100%;
        }
    }

    &__progress-container {
        &.watchandlearn__progress-container--overlay-watched,
        &.watchandlearn__progress-container--overlay-attended  {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: auto;
            width: 100%;
            opacity: .8;
            color: $color-primary-darkened-30;
            border-bottom-right-radius: $kt-border-bottom-right-radius;

            &::after {
              position: absolute;
              bottom: 0;
              bottom: 30px;
              width: 100%;
              color: $white;
              text-align: center;
              font-weight: $global-weight-bold;
              opacity: 1;
              z-index: 1;
              border-bottom-right-radius: $kt-border-bottom-right-radius;
            }
          }

        &.watchandlearn__progress-container--overlay-watched {
            background-color: $color-primary-darkened-30;

          &::after {
            content: 'WATCHED';
          }
        }

        &.watchandlearn__progress-container--overlay-attended {
          background-color: $color-secondary;

          &::after {
              content: 'ATTENDED';
          }
        }
    }

    &__progress {
      position: absolute;
      bottom: 20px;
      height: .5em;
      width: 80%;
      background-color: $iron;
      margin: 0 10%;
      border-radius: 50px;

      .watchandlearn__progress-bar {
          position: absolute;
          bottom: 0;
          height: .5em;
          background-color: $color-primary-darkened-30;
          border-radius: 50px;
      }
    }

    &__progress-box {
      display: flex;
      align-items: center;
    }

    &__progress-box-caption {
      font-size: 1rem;
    }

    &__body-link {
        text-decoration: none;
    }

    &__item {
        a {
            color: $black;
            text-decoration: none;
        }

        &--contribution {
            width: 100%;
            img {
                width: 100%;
            }
        }

        &--minor {
            img {
                width: 100%;
            }

            .watchandlearn__hero {
                margin-bottom: 1rem;
            }

            @include breakpoint(medium) {
                p {
                    font-size: .9rem;
                }
            }
        }
    }

    &__sidebar {
        width: 100%;
    }

    &__date {
        display: block;
        margin-bottom: 0;
        color: $color-primary-darkened-30 !important;
    }

    &__duration {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $qing;
        color: $white;
        padding: .2em;
        font-size: .8rem; // <small> (<small> = 80%)

        @include breakpoint(medium) {
            font-size: .72rem; // .9 * <small> (<small> = 80%)
        }
    }

    @include breakpoint(middling) {
        &__main {
            margin-right: .65rem;
            margin-bottom: 0;
        }
        &__button-block {
            margin-left: 1.7rem;
            margin-right: 1.7rem;
            text-align: left;

            .button {
                margin-right: 1.2rem;
            }
        }
    }
}
