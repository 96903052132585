/*******************************************************************************
 * RESOURCE.CSS
 *******************************************************************************
 */

.resource {
    + .resource__name {
        margin-top: 0rem;
    }

    &__name {
        font-size: 1rem;
        font-weight: $global-weight-bold;

        + .resource__name {
            margin-top: 2rem;
        }
    }

    &__section {
        font-size: 1.3rem;
        font-weight: $global-weight-bold;
    }
}
