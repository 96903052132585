/*******************************************************************************
FOOTER.CSS
*******************************************************************************/

footer {
    flex: none;
    min-height: $footer-height;
    color: $black;
    background-color: $white;
    padding-top: #{ 1.2rem + map-get($grid-column-gutter, small) / 2};
    font-size: .9rem;
    position: relative;

    a {
        color: $color-alert;

        &:active,
        &:visited {
            color: $color-alert;
        }

        &:hover {
            color: $color-alert-darkened-10;
        }

    }

    p, ul, ol, dl {
        line-height: 1.67;
    }

    &.footer--padded {
        padding-bottom: calc(1.2rem + #{$register-prompt-height-small}) !important;

        @include breakpoint(medium) {
            padding-bottom: calc(1.2rem + #{$register-prompt-height-medium}) !important;
        }
    }

    .footer__list {
        list-style-type: none;
        margin-left: 0;
    }

    .footer__main {
        position: relative;
        padding-top: 1.5rem;
        margin-bottom: .5rem;
    }

    .footer__logos {
        display: flex;
        align-items: flex-end;
    }

    .footer__colophon {
        height: 1.3em;
        width: 1.3em;
        position: absolute;
        top: -.7em;
        left: calc(50% - 1.5em/2);
    }

    .footer__menu {
        .menu {
            display: inherit;
            position: relative;
            border-top: 1px solid $jumbo;
            padding: 1.5rem 0;

            li {
                display: list-item;
            }

            li a {
                padding-bottom: 0;
                padding-left: 0;
                color: $color-alert;
            }

            li:first-child a {
                padding-left: 0;
            }

            li:last-child a {
                padding-right: 0;
            }

            .menu__unlinked {
                padding-top: .7rem;
            }
        }
    }

    @include breakpoint(medium) {
        // .footer__column.footer__column--left {
        //     // margin-right: 1rem;
        // }

        .footer__column.footer__column--center .footer__column-detail {
            padding-right: 1.5rem;
        }

        // .footer__column.footer__column--right {
        //     // margin-left: 1rem;
        // }

        .footer__column.footer__column--left .footer__column-detail,
        .footer__column.footer__column--center .footer__column-detail {
            height: 100%;
        }

        .footer__column-detail h3 {
            font-size: 1.2em;
            font-weight: bold;
        }

        .footer__menu {
            .menu {
                display: flex;
                li {
                    display: table-cell;
                }

                .menu__unlinked {
                    position: absolute;
                    top: 1rem;
                    right: 0;
                    // padding-top: 0;
                }
            }
        }
    }

    @include breakpoint(large) {
        .column, .columns {
            // padding-left: .5em;
            padding-left: 0!important;
            padding-right: 1em!important;
        }
    }

    .nzgovt-logo {
        height: 27px;
        margin: 1rem 0;
    }
    .nz-fern-logo {
       height: 64px;
       margin: 1rem 0;
    }
}
