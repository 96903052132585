/*******************************************************************************
 * TABS.CSS (OVERRIDES)
 *******************************************************************************
 */

.tabs {
    padding-left: .5rem;
    padding-right: .5rem;
    border-bottom: 0;

    &--single {
        padding-left: 0;
        padding-right: 0;
    }

    &-title {
        float: none;

        > a {
            text-align: center;
            font-size: 1.25rem;
            font-weight: $global-weight-bold;
            color: $white;
            text-decoration: none;
            background-color: $aluminum;
        }
    }

    &-title.tabs-title--register.is-active {
        > a {
            background-color: $color-primary-darkened-30;

            &:hover {
                background-color: $color-primary-darkened-40;
            }
        }
    }

    &-title.tabs-title--login.is-active {
        > a {
            background-color: $color-primary;

            &:hover {
                background-color: $color-primary-darkened-10;
            }
        }
    }

    &-panel {
        padding-top: 0;
    }

    &-content {
        border-top: 0;
    }

    @media screen and (min-width: 460px) {
        display: flex;
        justify-content: space-between;

        &-title {
            float: none;
            flex-grow: 1;
            width: 45%;

            &:first-child {
                margin-right: 1%;
            }
            &:last-child {
                margin-left: 1%;
            }
        }

        &-title.tabs-title--register.is-active {
            position: relative;
            > a {

                &:hover {
                    &:after {
                        position: absolute;
                        left: calc(50% - 1.2rem);
                        content: '';
                        @include triangle-bottom(1.2rem, $color-primary-darkened-40);
                    }
                }

                &:after {
                    position: absolute;
                    left: calc(50% - 1.2rem);
                    content: '';
                    @include triangle-bottom(1.2rem, $color-primary-darkened-30);
                }
            }
        }

        &-title.tabs-title--login.is-active {
            position: relative;
            > a {

                &:hover {
                    &:after {
                        position: absolute;
                        left: calc(50% - 1.2rem);
                        content: '';
                        @include triangle-bottom(1.2rem, $color-primary-darkened-10);
                    }
                }

                &:after {
                    position: absolute;
                    left: calc(50% - 1.2rem);
                    content: '';
                    @include triangle-bottom(1.2rem, $color-primary);
                }
            }
        }
    }
}
