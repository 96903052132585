/*******************************************************************************
 * SEARCH-BOX.CSS
 *******************************************************************************
 */

.search-box {
    position: relative;
    padding: 0 .5em;
    &.search-box--full-width {
        .columns {
            position: relative;
        }

        .button {
            position: absolute;
            top: 0;
            left: .5rem;
            padding: .49rem;
            font-size: 1.5rem;
            // height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));
            height: 2.5rem;
            background-color: transparent;
        }

        input {
            height: 2.5rem;
            line-height: 1;
            padding-top: 0;
            padding-bottom: 0;
            border-radius: 30px;
            padding-left: 50px;
        }

        .icon-search {
            color: $iron;
        }
    }

}
