.areas-of-expertise {
    background-color: #e0f1ee;

    a {
        color: $black;
        text-decoration: none;
        font-size: .79em;
    }


    .is-dropdown-submenu {
        background-color: $white;
        min-width: auto;
        border: 1px solid $iron;

        .is-dropdown-submenu-item:hover {
            background-color: $qing;

            a {
                color: $white;
            }
        }

        &>li.is-dropdown-submenu-item:not(:last-child)>a {
            border-bottom: 1px solid $iron;
        }
    }

    &.dropdown.menu>li.is-dropdown-submenu-parent {
        width: 100%;

        &>a {
            padding: .5rem;

            &:after {
                content: none;
            }
        }

        &>.is-dropdown-submenu {
            left: auto;
            right: 0;
        }
    }
}