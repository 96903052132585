@mixin caption {
    font-size: .85rem;
    border-bottom: 1px solid $graphite;
    padding-bottom: 1em;
}

@mixin triangle-bottom($size, $color) {
    width: 0;
    height: 0;
    bottom: -$size;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid currentColor;
    color: $color;
}
