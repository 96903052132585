/*******************************************************************************
 * ACCORDION-MENU.CSS (OVERRIDES)
 *******************************************************************************
 */

.is-accordion-submenu-parent > a::after {
    // display: none;
    transition: transform .5s;
    border-color: $mustard-900 transparent transparent;;
}
