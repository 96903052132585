/*******************************************************************************
 * BACK-TO-TOP.CSS
 *******************************************************************************
 */

.back-to-top {
    cursor: pointer;

    &__icon {
        margin-right: .5em;

        &:after {
            content: '\e803';
            font-family: chinacapable;
            color: $white;
            background-color: $secondary-color;
            padding: 0 .25em;
            font-size: 1.5em;
            font-style: normal;
        }
    }
}