/*******************************************************************************
 * PATHWAYS-MENU.CSS
 *******************************************************************************
 */

.pathways-bg {
  position: relative;

  @include breakpoint(medium) {
    height: 470px;
    background-image: url("/assets/img/versioned/banner/pathways-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.pathways-choice {
  background-color: $white;
  padding: 20px 40px;
  margin: 60px auto 12px auto;
  text-align: center;
}

.pathways-title {
  font-weight: 700;
  color: $teal-900;
  padding-top: 10px;
}

.pathways-text {
  padding: 10px 0;
  color: $teal-900;
}

.pathways-menu {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
  flex-flow: row wrap;

  &__list {
    background-color: $teal-800;
    margin-right: 0.5em;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 8.5em;
    transition: background-color 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &:hover {
      background-color: lighten($teal-800, 10%);
      cursor: pointer;
    }

    a {
      color: $white;
      text-decoration: none;
      margin: 0 0.5em;
      font-size: 1.25em;
      font-family: "FF DIN Pro Medium";
    }
  }
  @include breakpoint(middling) {
    &__list {
      width: 8.5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
