/*******************************************************************************
 * PROMPT.CSS
 *******************************************************************************
 */

.prompt {
    color: $white;
    background-color: $color-primary-darkened-30;
    padding: 1rem;
    font-size: 1.5rem;
    z-index: 1;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
}


.prompt__button-container {
    text-align: center;

    @include breakpoint(medium) {
        text-align: right;
        padding-left: 0;
    }
}

