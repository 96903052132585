/*******************************************************************************
 * EVENT-BLOCK.CSS
 *******************************************************************************
 */

.event-block {
    padding-top: 1.5em;
    padding: 2rem;
    border-top: 0;
    width: 100%;
    text-align: left;
    margin-bottom: .5rem;

    p {
        line-height: 1.5;
        margin-bottom: 0;
    }

    &__heading {
        display: inline-block;
        padding-bottom: .05em;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid $tertiary-color;
    }

    &__subheading {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        color: white;
        padding: .5rem;
        font-size: .75rem;
        line-height: 1;
        padding-top: .65rem;

        &--upcoming {
            background-color: $primary-color;
        }

        &--previous {
            background-color: #808080;
        }
    }

    &__image-link {
        display: block;
    }

    &__thumbnail {
        margin-top: calc(-.75rem - .5rem - .5rem - .5rem - .15rem);
        margin-bottom: 1rem;
        z-index: -1;
    }

    &__date {
        color: #808080;
        font-size: .85rem;
        font-weight: $global-weight-bold;
        margin-bottom: .5rem;
    }

    &__event-heading {
        color: $color-primary-darkened-30;

        a {
            color: $color-primary-darkened-30;
            text-decoration: none;
        }
    }

    &__event-link {
        line-height: 1;

        a {
            font-size: .85rem;
            color: $color-alert;
            text-decoration: none;

            &:hover {
                color: $color-alert-darkened-10;
            }

            &::before {
                color: $color-alert;
                border: 0;
                margin-left: 0;
                margin-right: .25rem;

                &:hover {
                    color: $color-alert-darkened-10;
                }
            }
        }
    }
}

@include breakpoint(middling) {
    .event-block {
        width: 50%;
        margin-bottom: 0;
    }
}
