/*******************************************************************************
 * CONTRIBUTIONS.CSS
 *******************************************************************************
 */

.contributions {
    &__heading {
        &--decorated {
            border-top: 1px solid $graphite;
            padding-top: 1rem;
        }
    }
}
