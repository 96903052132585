/*******************************************************************************
 * PROGRESS-BAR.CSS
 *******************************************************************************
 */

.progress-bar {
    display: flex;
    align-items: center;

    &__background {
        background-color: $mercury;
        width: 300px;
        height: 2rem;
    }

    &__completed {
        height: 2em;
        background-color: $color-primary;
    }

    &__label-container {
        line-height: 1;
        margin-left: .5rem;
    }
}
