/*******************************************************************************
 * FEEDBACK.CSS
 *******************************************************************************
 */

.feedback {
    width: 100%;
    text-align: center;

    &__button {
        font-size: .875em;
        font-weight: $global-weight-bold;
        color: $white;
        background-color: $color-alert;
        padding: 1.1rem 1.7rem;
        border-radius: 32px 33px 0 0;
        cursor: pointer;
        //z-index: 1;
        transition: background-color .25s ease-in-out;

        &:hover {
            background-color: $color-alert-lighten-10;
        }
        z-index: 1;
    }

    @include breakpoint(medium) {
        &__button {
            position: fixed;
            top: calc(50vh - 50px);
            right: 0;
            transform: rotate(270deg);
            transform-origin: bottom right;
        }
    }
}
