/*******************************************************************************
 * CAPTION-BLOCK.CSS
 *******************************************************************************
 */

.caption-block {
    &__heading {
        &--inline {
            display: inline-block;
        }

        &--monotone {
            border-bottom: 2px solid $white;
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: .875rem;

        &.caption-block__title--kts {
            color: $aubergine;
        }

        &.caption-block__title--pathways {
          color: $teal-900;
        }
    }

    &__flash {
        display: inline-block;
        margin-left: -1.7em;
        padding: .75em 1.7em;
        color: $oil;
        background-color: $color-tertiary;
        font-weight: $global-weight-bold;
    }

    &--padded {
        padding: 1em 1.7em;
    }

    &--inverse {
        color: $white;
        background-color: $oil;
    }

    &--home {
        position: relative;
        padding-top: 1.2em;
        padding-left: 1.2em;
        padding-right: 1.2em;
        color: $white;
        background-color: $oil;

        .caption-block__heading {
            font-size: 1.2rem;
            padding-bottom: .5rem;
            margin-bottom: 1rem;
        }
    }
}
