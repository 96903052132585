/*******************************************************************************
 * WRAPPER.CSS
 *******************************************************************************
 */

.wrapper {
    width: 100%;
    margin: auto;
    max-width: $wrapper-width;
    padding-bottom: 1.2rem;

    &.wrapper--homepage {
        max-width: none;
    }

    &.wrapper--padless-small {
        padding-left: 0;
        padding-right: 0;
    }

    &.wrapper--shadowless {
        box-shadow: none;
        padding-bottom: 0;
    }

    &.wrapper--transparent {
        background-color: transparent;
    }

    @include breakpoint(medium) {
        padding-left: 2rem;
        padding-right: 1.2rem;
    }
}
