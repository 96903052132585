/*******************************************************************************
 * SEARCH-RESULT.CSS
 *******************************************************************************
 */

.search-result {
    margin-bottom: 2rem;

    &__description {
       > p:last-child {
            margin-bottom: 0;
       }
    }
}

