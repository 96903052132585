/*******************************************************************************
 * INTRODUCTION-BLOCK.CSS
 *******************************************************************************
 */

.introduction-blocks {
    &__container {
        margin-bottom: 2rem;
    }
}

.introduction-block {
    height: 100%;

    &__heading {
        font-size: 1.625rem;
        font-weight: $global-weight-bold;
        color: $oil;
    }

    &__heading-link {
        text-decoration: none;
    }

    &__link a {
        font-size: .85rem;
        color: $color-alert;
        text-decoration: none;
    }

    &__thumbnail {
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }

    &__subheading {
        display: inline-block;
        margin-bottom: 1rem;
        padding-bottom: .25rem;
        font-size: 1.25rem;
        width: 100%;
        text-align: center;

        &:not(:first-of-type) {
            // margin-top: 1rem;
            padding-top: 1rem;
            border-top: 2px solid $white;
        }
    }

    &__subsubheading {
        font-size: 1.1em;
        text-decoration: none;
        border-bottom: 1px solid lighten($secondary-color, 50%);
        padding-bottom: .25rem;

        &:hover {
            color: $color-primary-darkened-30;
            border-bottom: 1px solid $color-primary-darkened-30;
        }
    }

    &__icon {
        max-width: 5rem;
        max-height: 5rem;
        margin-top: 1rem;
    }

    &__body {
        line-height: 1.2;

        .event-block__event-link {
            display: none;
        }

        &.introduction-block__body--padded {
            padding: 0rem 1rem;
        }
    }

    &.introduction-block--bordered {
        border-top: 2px solid lighten($secondary-color, 50%);
        padding-top: 1rem;
    }

    &.introduction-block--solid {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        background-color: #f2f2f2;

        .introduction-block__heading {
            position: relative;
            padding: 1rem;
            background-color: #e6e6e6;
            margin-left: -.5rem;
            margin-right: -.5rem;
            margin-bottom: 2rem;

            &:after {
                position: absolute;
                left: 1.5rem;
                content: '';
                width: 0;
                height: 0;
                bottom: -1.2rem;
                border-left: 1.2rem solid transparent;
                border-right: 1.2rem solid transparent;
                border-top: 1.2rem solid #e6e6e6;
            }
        }

    }

    &.introduction-block--flexed {
        .introduction-block__heading {
            height: 2rem;
        }

        .introduction-block__content-block {
            height: calc(100% - 2.75rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    @include breakpoint(middling) {
        &__subheading {
            text-align: left;
        }
    }
}