/*******************************************************************************
 * EVENT-TAG.CSS
 *******************************************************************************
 */

 .event-tag {
    display: inline-block;
    text-transform: uppercase;
    color: $white;
    padding: .25em .5em;

    &--upcoming {
        background-color: #3d998a;
    }

    &--past {
        background-color: #999;
    }
 }