/*******************************************************************************
 * COLLECTION-ITEM.CSS
 *******************************************************************************
 */

.collection-items {
  display: block;

  @include breakpoint(medium) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.collection-item {
  position: relative;
  display: block;
  max-width: 100%;
  margin-bottom: 1rem;

  min-height: 140px;
  padding: 1.2rem;
  background-color: $white;
  color: $black;
  text-decoration: none;

  h2 {
    font-size: 1rem;
    font-family: $body-font-family;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: 2rem;
    margin-left: -1.2rem;
    padding-left: 1.2rem;
    background-color: $teal-500;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .6875rem;
  }

  @include breakpoint(medium) {
    flex: 0 1 32%; // 1024 - (2 * 16) / 1024
    max-width: 327px; // need for IE
    margin-bottom: 5rem;

    // Style squiggly line going down from right-to-left
    &:nth-child(3n)::before {
      content: '';
      position: absolute;
      bottom: -3.25rem;
      left: -140%;
      right: 50%;
      border: 1.25rem solid #46757e;
      border-radius: 4rem;
      padding: 2.75rem;
      z-index: -1;
      border-left: none;
      border-bottom-left-radius: 0;
    }

    // Style squiggly line going up from left-to-right
    &:nth-child(3n+4)::before {
      content: '';
      position: absolute;
      top: -3rem;
      right: -140%;
      left: 50%;
      border: 1.25rem solid #46757e;
      border-radius: 4rem;
      padding: 2.75rem;
      z-index: -1;
      border-right: none;
      border-bottom: none;
      border-top-right-radius: 0;
    }

    &:nth-last-child(-n+3) {
      margin-bottom: 0;
    }

    // Ensure squiggly line doesn't appear under last row
    &:last-child {
      &::before {
        border: none;
      }
    }
  }

  &::after {
    content: '\e80b';
    font-family: chinacapable;
    text-align: center;
    line-height: 1.9;
    color: $white;
    position: absolute;
    width: 2em;
    height: 2em;
    background-color: $qing;
    bottom: 1rem;
    right: 1em;
    border-radius: 50%;
  }

  &.collection-item--hidden {
    display: none;

    @include breakpoint(medium) {
      display: block;
      visibility: hidden;
    }
  }

  &.collection-item--completed {
    background-color: $teal-500;

    &::after {
      content: '\e81f';
      color: $qing;
      background-color: $white;
    }
  }
}
