/*******************************************************************************
 * FIGURE.CSS
 *******************************************************************************
 */

.figure {
    margin: 1rem 0;

    &__image {
        margin-bottom: 1rem;
    }

    &--one-of-two {
        width: 100%;
    }

    &--left {
        float: left;
        margin: 0 0 1rem 0;
    }

    &--right {
        float: right;
        margin: 0 0 1rem 0;
    }

    figcaption {
        @include caption
    }

    &__attribution {
        font-style: italic;
    }

    @media (min-width: 1024px) {
        &--one-of-two {
            width: 38%;
        }

        &--full {
            width: 100%;
        }

        &--right {
            margin: 0 0 1rem 1rem;
        }

        &--left {
            margin: 0 1rem 1rem 0;
        }
    }
}
