/*******************************************************************************
 * COLLECTION-LIST.CSS
 *******************************************************************************
 */

 .collection-list {
    list-style-type: none;
    margin-left: 0;

    &__item {
        margin-bottom: .75rem;
        background-color: $white;;
        transition: background-color 0.25s ease-in-out;

        &:first-child {
            margin-top: 2.4rem;
        }

        &:hover {
            background-color: $snifter;
        }

        &--padded {
            padding: .75em 1em;

            &:hover {
                background-color: $white;
            }
        }

        &--no-hover {
            &:hover {
                background-color: $white;
            }
        }

        &.collection-list__item--short-margin-bottom {
            &:last-child {
                margin-bottom: 1.2rem;
    }
        }
    }

    &__link {
        color: $black;
        text-decoration: none;
    }

    &__item-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .75em .75em 0 .75em;
        font-size: 1.25em;
        color: $black;
        text-decoration: none;
    }

    &__item-block:last-child {
        padding: .75em;
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.7em;
        height: 1.7em;
        background-color: $white;
        transition: background-color 0.25s ease-in-out;
        border: 1px solid $black;
        color: $white;
        margin-right: .5rem;

        &--hover {
            cursor: pointer;

            &:hover {
              background-color: darken($aubergine, 10%);
            }
        }

        &--viewed {
            background-color: $aubergine;
            border: none;
            cursor: pointer;

            &:hover {
              background-color: darken($aubergine, 10%);
            }
        }

        &--watched {
            background-color: $color-primary;
            border: none;
        }

        &--attended {
            background-color: $aubergine;
            border: none;
            cursor: pointer;

            &:hover {
              background-color: darken($aubergine, 10%);
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;

        > *:not(:last-child) {
            color: $aluminum;
            margin-right: 1em;
            font-size: 1.75rem
        }
    }
}
