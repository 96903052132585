/*******************************************************************************
 * FILTER-BAR.CSS
 *******************************************************************************
 */

 .filter-bar {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
    flex-wrap: wrap;

    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }

    &__block {
        flex: 1 0 100%;

        @include breakpoint(medium) {
            flex: 1;

            &:not(:last-child) {
                margin-right: .5em;
            }
        }
    }

    &__panel {
        display: flex;
        background-color: #e6e6e6;
        padding: .5em;
        align-items: center;
        cursor: pointer;
        transition: background-color .25s ease-in-out;
        min-height: 3.5rem;

        @include breakpoint(medium) {
            margin-bottom: .5em;
        }

        &:hover {
            background-color: darken(#e6e6e6, 10%);
        }

        &--menu {
            padding: 0;
            align-items: stretch;
            background-color: #e0f1ee;

            &:hover {
                background-color: darken(#e0f1ee, 10%);
            }
        }
    }

    &__content {
        margin: 0 .5em 0 0;
        font-size: .79em;
    }

    &__input {
        margin: 0;
    }

    &__link {
        font-size: .85em;
        color: $qing;
        margin-bottom: 1em;

        &:hover {
            color: darken($qing, 10%);
        }

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }

    &__icon {
        flex: 0;

        @include breakpoint(medium) {
            flex: 1 0 auto;
        }
    }
}
