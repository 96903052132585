/*******************************************************************************
 * BANNER-TEXT.CSS
 *******************************************************************************
 */

.banner-text {
  text-align: center;
  position: relative;
  margin-top: 68px;

  &--loggedin {
    background: $teal-400;
    margin-top: 0;
  }

  &--notloggedin {
    margin-top: 3rem;

    .banner-text__sub-heading {
      margin-top: 0;
    }

    .banner-text__sub-heading-link {
      color: $ochre;
    }

    .banner-text__blurb {
      color: $teal-900;
      font-size: 1.25rem;
      font-weight: bold;
      max-width: 560px;
      margin: auto;
    }
  }

  &__heading {
    display: inline-block;
    border-bottom: 1px solid $graphite;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  &__heading.banner-text__heading--new {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 1.2rem;
    font-weight: $global-weight-bold;
  }

  &__sub-heading {
    @include breakpoint(medium) {
      color: $teal-900;
      font-weight: $global-weight-bold;
    }
  }

  &__body {
    font-weight: $global-weight-bold;
    font-size: 1.25rem;
  }

  &--inner {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-top: 50px;
  }

  @include breakpoint(medium) {
    &--notloggedin {
      margin-top: 1rem;
    }

    &--inner {
      margin-left: calc(50% - 1024px / 2);
      margin-right: calc(50% - 1024px / 2);
    }

    &__heading {
      color: $teal-900;
      font-size: 2rem;
      letter-spacing: -0.03rem;
    }

    &__body {
      color: $white;
      font-size: 1.5rem;
    }
  }
}
