/*******************************************************************************
 * COLOPHON.CSS
 *******************************************************************************
 */

.colophon {
  position: relative;
  margin-top: 5rem;

  &::after {
    position: absolute;
    content: url(/assets/img/versioned/colophon.svg);
    height: 1.25rem;
    width: 1.25rem;
    top: -3.5rem;
    left: 0;
  }
}

.colophon-block-header {
  height: 1.3em;
  width: 1.3em;

  &.colophon-block-header--upcoming {
    margin-bottom: 1rem;
  }

  &.colophon-block-header--positioned {
    position: absolute;
    left: calc(50% - 1.5em / 2);
    top: -0.7em;
  }
}
