.u {
    &-center {
        text-align: center !important;
    }

    &-inverse {
        background-color: $light-gray !important;

        h1, h2, h3, h4, h5, h6 {
            color: $color-primary-darkened-30 !important;
        }

        p {
            color: $color-primary-darkened-30 !important;
        }

        a {
            color: $color-primary-darkened-30 !important;

            &:hover {
                color: darken($color-primary-darkened-30, 20%) !important;
            }
        }

        label {
            color: $color-primary-darkened-30 !important;
        }

        li {
            color: $color-primary-darkened-30 !important;
        }

        .is-invalid-input:not(:focus) {
            background-color: $white;
        }

        .callout p {
            color: $alert-color !important;
        }
    }

    &-white {
        color: $white !important;
    }

    &-background-white {
      background-color: $white !important;
    }

    // https://css-tricks.com/full-width-containers-limited-width-parents
    &-full-width {
        margin-left: -1.2rem !important;
        margin-right: -1.2rem !important;

/*
        max-width: $wrapper-width !important;
        margin-left: calc(50% - 50vw) !important;
        margin-right: calc(50% - 50vw) !important;
 */

/*
        width: $wrapper-width;
        position: relative;
        left: calc(50% - #{$wrapper-width} / 2);
        right: calc(50% - #{$wrapper-width} / 2);
*/
    }

    &-normal-width-article {
      margin-left: 1.7rem !important;
      margin-right: 1.7rem !important;
    }

    &-full-width-article {
      margin-left: -1.7rem !important;
      margin-right: -1.7rem !important;
      max-width: inherit !important;
    }

    &-double-extra-negative-margin-left {
      margin-left: -3.6rem !important;
    }

    &-clear-menu {
        /*
        ** https://css-tricks.com/hash-tag-links-padding/
        */
        display: block;

        &::before {
            display: block;
            content: ' ';
            margin-top: calc(-85px - 1rem); // account for 4em padding
            height: calc(85px + 1rem); // account for 4em padding
            visibility: hidden;
            pointer-events: none;
        }
    }

    // Ensure child items (columns) of the container all have an equal height
    &-equalised {
        @include breakpoint(medium up) {
            display: flex;
            align-items: stretch;
        }
    }

    &-no-text-decoration {
        text-decoration: none !important;
    }

    &-no-margin {
      margin: 0 !important;
    }

    &-no-side-margins {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    &-no-margin-top {
        margin-top: 0 !important;
    }

    &-no-margin-bottom {
        margin-bottom: 0 !important;
    }

    &-medium-no-padding-left {
        @include breakpoint(medium up) {
            padding-left: 0 !important;
        }
    }

    &-medium-no-padding-right {
        @include breakpoint(medium up) {
            padding-right: 0 !important;
        }
    }

    &-no-padding {
        padding: 0 !important;
    }

    &-no-padding-bottom {
        padding-bottom: 0 !important;
    }

    &-double-padded-top {
        padding-top: 1.2rem !important;
    }

    &-double-padded-bottom {
        padding-bottom: 1.2rem !important;
    }

    &-double-padded-left {
        padding-left: 1.2rem;
    }

    &-double-padded-right {
        padding-right: 1.2rem;
    }

    &-double-padded {
        padding: 1.2rem;
    }

    &-triple-extra-padded-bottom {
        padding-bottom: 1.7rem;
    }

    &-triple-extra-padded-left {
        padding-left: 1.7rem;
    }

    &-triple-extra-padded-right {
        padding-right: 1.7rem;
    }

    &-double-extra-padded-left {
        padding-left: 1.5rem;
    }

    &-double-extra-padded-right {
        padding-right: 1.5rem;
    }

    &-triple-padded {
      padding: 1.7rem !important;
    }

    &-quad-padded-top {
      padding-top: 2.4rem !important;
    }

    &-quad-padded-bottom {
        padding-bottom: 2.4rem !important;
    }

    &-quad-padded-left {
        padding-left: 2.4rem;
    }

    &-quad-padded-right {
        padding-right: 2.4rem;
    }

    &-tiny-margin-bottom {
      margin-bottom: .6rem !important;
    }

    &-tiny-margin-right {
      margin-right: .6rem !important;
    }

    &-tiny-margin-left {
        margin-left: .6rem !important;
    }

    &-short-margin-left {
        margin-left: 1.2rem !important;
    }

    &-short-margin-top {
        margin-top: 1.2rem !important;
    }

    &-short-margin-bottom {
      margin-bottom: 1.2rem !important;
    }

    &-short-margin-right {
      margin-right: 1.2rem !important;
    }

    &-double-margin-top {
        margin-top: 2.4rem !important;
    }

    &-double-margin-bottom {
        margin-bottom: 2.4rem !important;
    }

    &-double-margin-left {
      margin-left: 2.4rem !important;
    }

    &-double-margin-right {
      margin-right: 2.4rem !important;
    }

    &-double-extra-margin-bottom {
      margin-bottom: 3.6rem !important;
    }

    &-quad-margin-top {
      margin-top: 4.8rem !important;
    }

    &-img-full-width {
        width: 100% !important;
    }

    &-columns-2 {
        columns: 300px 2;

        & ul {
            // Ensure list and any margin-bottom don't break over columns
            display: inline-block;
        }
    }

    &-clearfix {
        &:before {
            content: ' ';
            display: table;
        }
        &:after {
            content: ' ';
            display: table;
            clear: both;
        }
    }

    &-compulsory:before {
        content: '* ';
        color: $alert-color;
    }

    &-default {
        cursor: default !important;
    }

    &-pointer {
        cursor: pointer !important;
    }

    &-wait {
        cursor: wait !important;
    }

    &-vertically-centered {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &-center {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
    }

    &-center-vertical {
        display: flex;
        align-items: center !important;
    }

    &-center-vertical-baseline {
      display: flex;
      align-items: baseline !important;
    }

    &-force-justify {
      display: flex !important;
      justify-content: space-between !important;
      align-items: baseline;
    }

    &-font-normal {
        font-weight: normal !important;
    }

    &-text-center {
        text-align: center !important;
    }

    &-text-left {
      text-align: left !important;
  }

    &-hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        background-color: $primary-color;
        opacity: 0;

        &:hover {
            opacity: 1;
            transition: opacity .25s ease-in-out;
        }
    }

    &-border {
        border: 1px solid $iron;
    }

    &-no-border {
        border: none !important;
    }

    &-fadein {
        opacity: 1 !important;
        transition: opacity .5s ease-in-out !important;
    }

    &-aligned-bottom {
        position: relative;
        padding-bottom: 2.5rem !important;
    }

    &-align-bottom {
        position: absolute;
        bottom: 1rem;
        margin-bottom: 0;
    }

    &-panel-aligned-bottom {
        position: relative;
        padding-bottom: 4rem;
    }

    &-panel-align-bottom {
        position: absolute;
        bottom: 0;
    }

    &-list-unstyled {
        list-style-type: none !important;
        margin-left: 0 !important;
    }

    &-align-left {
        text-align: left !important;
    }

    &-align-center {
        text-align: center !important;
    }

    &-align-right {
        text-align: right !important;
    }

    &-color-ochre {
      color: $ochre;
    }

    &-color-teal-900 {
      color: $teal-900;
    }

    &-color-qing {
        color: $qing;
    }

    &-color-inherit {
        color: inherit !important;
    }

    &-color-background-pewter {
        background-color: $pewter !important;
    }

    &-hide {
        display: none !important;
    }

    &-no-outline:focus {
        outline: none;
    }
}
