/*******************************************************************************
 * DETAIL.CSS
 *******************************************************************************
 */

.detail {
    @include breakpoint(medium) {
        // Cope with the situation where anchors disappear behind the fixed
        // header when visited.
        // http://nicolasgallagher.com/jump-links-and-viewport-positioning/
        a[name]:before {
          content: "";
          display: block;
          height: 85px;
          margin: -85px 0 0;
        }
    }
}
