/*******************************************************************************
 * PANEL-GROUP.CSS
 *******************************************************************************
 */

.panel-group {
    &__heading-block {
        display: flex;
        justify-content: space-between;
    }

    &__list {
        list-style-type: none;
        margin-top: .5rem;
        margin-left: 0;

        ul {
            margin-bottom: 1rem;
        }
    }

    &__list-item {
      margin-left: 0;
      margin-right: 0;

      @include breakpoint(medium) {
        margin-left: -1.7rem;
        margin-right: -1.7rem;
      }
    }

    &__list-item-block {
        position: relative;
        padding: 1em;
        background-color: $white;
        margin-bottom: 1.7rem;

        &.panel-group__list-item-block--flashed {
            padding-top: 4em;
        }

        &.panel-group__list-item-block--simple {
            padding: 0 0 2em 0;
            border-bottom: 2px solid $color-tertiary;
        }

        &.panel-group__list-item-block--inverse {
          background-color: transparent;
          color: $white;
        }
      }

    &__list-item-block-detail {
      flex-wrap: wrap;

      @include breakpoint(middling) {
          flex-wrap: nowrap;
      }
    }

    &__list-item-image-collection {
        flex: 1 0 auto;
        width: 100%;
        height: 120px;
        background-size: cover;
        background-position: center;
        margin-bottom: 1rem;

        @include breakpoint(middling) {
            width: 120px;
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }

    &__flash {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        background-color: $color-tertiary;
        padding: .6em 1.2em;
        color: $black;
        text-decoration: none;
    }

    &__heading a {
        color: $black;
        text-decoration: none;
    }
}
