/*******************************************************************************
 * MENU.CSS
 *******************************************************************************
 */

.menu {
    > li.has-form {
        display: block;
        padding: 0.7rem .5rem;
        line-height: 1;
    }

    &.vertical--boxed {
        > li {
            line-height: 1;
        }

        > li > a,
        > li > .vertical__active,
        > li > .vertical__date {
            padding: 0 1rem 0.5rem 0;
            line-height: 1.25;
        }

        .vertical__active,
        .vertical__date {
            display: block;
            padding-bottom: .5rem;
        }

        .vertical__active {
            font-weight: $global-weight-bold;
        }

        .menu.vertical--boxed {
            padding-left: 1rem;
        }

    }
}
