/*******************************************************************************
 * MEDIA-PATH.CSS
 *******************************************************************************
 */

.media-path {
    position: relative;
    margin-left: -2rem;
    margin-right: -1.2rem;
    padding: 2rem;
    background-color: $teal-900;

    &--knowledge {
        background-color: $aubergine;
    }

    &__colophon {
        height: 1.3em;
        width: 1.3em;
        position: absolute;
        top: -.7em;
        left: calc(50% - 1.5em/2);
    }

    .row {
        justify-content: center;
    }

    &__title {
        width: 100%;
        text-align: center;
        font-size: 1.625rem;
        font-weight: bold;
        color: $white;
    }

    &.media-path--transfer {
      padding-top: 2.5rem;
      background-color: $white;
      text-align: center;

      .media-path__title {
        color: #28515C;
      }
    }

    &__block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1rem;

        @include breakpoint(800px) {
          flex-wrap: nowrap;
        }
    }

    &__text {
        color: $white;
        width: 361px;
        margin: 2rem 1rem 1rem 1rem;
    }

    &__img {
        margin: 1rem;
    }

    &__buttons {
        .button {
            background: none;
            border: 2px solid $white;
            margin-left: .5rem!important;
            margin-right: .5rem!important;
            margin-bottom: 0;
        }
    }

    &__item {
        background: $white;
        transition: opacity .25s ease-in-out;

        &:hover {
          opacity: .9;
        }

        @include breakpoint(medium) {
          width: 334px;
        }
    }

    @include breakpoint(middling) {
        padding: 2.5rem 2rem 3rem;
        margin-left: -1.2rem;

        &__block {
            margin-top: 3.35rem;
        }

        &__text {
            margin-top: 0;
            margin-left: 2rem;
        }
        &__buttons {
            margin-top: 5.5rem;
            .button {
                width: 220px;
                margin-bottom: 1rem;
            }
        }
        &__img {
            margin: 0;
        }
    }
}
