/*******************************************************************************
 * ICON.CSS
 *******************************************************************************
 */

.icon {

    &::before {
        font-family: "chinacapable";
        font-size: 2rem;
        color: darken($primary-color, 20%);
        border: 0;
        margin: 0;
    }

    // A fallback in case no specific icon has been specified.
    // Icons are normally as per those defined in the fontello css.
    &.icon-fallback {
        &::before {
            content: '\f15c';
        }
    }

    &--small {
        &::before {
            font-size: 1rem;
        }
    }

    &--green:before {
      color: $teal-700;
    }

    &.icon--yellow:before {
        color: $color-tertiary;
    }

}
