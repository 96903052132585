/*******************************************************************************
HEADER.CSS
*******************************************************************************/

// As per https://foundation.zurb.com/building-blocks/blocks/scrollhide-nav.html
$header-transition-speed: 1s;

header,
.header {
    flex: none;
    position: relative;
    background-color: $primary-color;
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    width: 100vw;
    transition: transform $header-transition-speed cubic-bezier(0.86, 0, 0.07, 1);

    &.header--hidden {
        transform: translateY(-100%);
    }

    h1 {
        font-size: 2em;
        line-height: 1.2;
    }

    .header__head {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .header__body {
        padding-top: #{ map-get($grid-column-gutter, small) / 2};
        padding-left: #{ map-get($grid-column-gutter, small) / 2};
        padding-right: #{ map-get($grid-column-gutter, small) / 2};
        padding-bottom: #{ map-get($grid-column-gutter, small) / 2};
    }

    @include breakpoint(medium) {
        width: 100%;

        h1 {
            font-size: 2.5em;
        }

        .header__body {
            transform: translateY(35%);
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
