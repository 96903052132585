/*******************************************************************************
 * FEEDBACK.CSS
 *******************************************************************************
 */

.feed-blocks {
    position: relative;
    margin-bottom: 1rem;

    &__title {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        font-weight: $global-weight-bold;
        font-size: .85rem;
        background-color: $color-primary;
        padding: .2rem .85rem;
    }
}

.feed-block {
    display: block;
    text-decoration: none;
    color: $black;
    background-color: $light-gray;
    transition: background-color 0.25s ease-in-out;
    padding: .5rem 1rem 0 1rem;

    &:hover {
        background-color: $iron;
    }
    &:first-child {
        padding-top: 2rem;
    }
    &__title {
        margin-bottom: 0;
    }
    &__date {
        font-size: .75rem;
        color: $steel;
        margin-bottom: 0;
        padding-bottom: .5rem;
        border-bottom: 1px solid $white;
    }
}
