/*******************************************************************************
 * STYLEGUIDE.CSS
 *******************************************************************************
 */

.styleguide {
  &__heading {
    background-color: $qing;
    color: $white;
    padding: 0.75rem;
  }

  &__section {
    padding: 0 0 2em;
  }

  &__body {
    padding: 0.75rem;
  }
}
