/*******************************************************************************
 * ROW.CSS (OVERRIDES)
 *******************************************************************************
 */

 .row {
    &--top {
        padding-top: 3rem;
    }

    &--inverse {
        color: $white;
        background-color: $oil;

        h1 {
            color: $white;
        }

        a {
            color: $iron;

            &:hover {
                color: $snifter;
            }
        }

        .breadcrumbs {
            a {
                color: $white;
                transition: color .25s ease-in-out;
            }

            li:not(:last-child)::after {
                color: $white;
            }

        }
    }

    &--full-width {
      margin-left: -1.7rem !important;
      margin-right: -1.7rem !important;
    }

    &.row--no-side-margin {
      margin-left: 0;
      margin-right: 0;
    }

    &.row--grey {
        background-color: $iron;
    }

    &.row--breakout {
        margin-left: -2.75rem;
        margin-right: -2.75rem;
        padding-left: 1.15rem;
        padding-right: 1.15rem;
        background-color: $aluminum;
    }

    &.row--breakout-light {
        margin-left: -1.75rem;
        margin-right: -1.75rem;
        padding-left: 1.75em;
        padding-right: 1.75em;
        padding-top: 1em;
        background-color: $iron;
    }

    &.row--breakout-lighter {
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: .5em;
        padding-right: .5em;
        padding-top: 1em;
        background-color: $mercury;
    }
}
