/*******************************************************************************
ARTICLE.CSS
*******************************************************************************/

article a {
  color: $color-alert;
}

/*
** https://css-tricks.com/hash-tag-links-padding/
*/
.article__body {
    h2 {
      margin-top: 1.5rem;
    }
    &--kt {
        background-color: $aubergine;
        color: white;
    }

    &--pathway {
        margin-top: 2rem;
        background-color: $teal-900;
        color: white;
    }

    &--general, &--general-kt {
      h2 {
        font-size: 1.25rem;
        font-family: $body-font-family;
      }
      h3 {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    &--general {
      .summary {
        p:last-child,
        ul:last-child {
            font-style: italic;
        }
      }
    }
  }

.article__colophon {
    height: 1.3em;
    width: 1.3em;
    margin-bottom: 30px;
}
