/*******************************************************************************
 * MEDIA-OBJECT.CSS
 *******************************************************************************
 */

.media-object {

    margin-bottom: 1rem;

    .media-object-section--aside {
        a {
            text-decoration: none;
        }
    }

    .media-object-section--aside-flexed {
        flex: 1 1 100%;
    }

    .media-object-section--linkless-left {
        // flex: 1 0 30%;
        flex: 1 1 0px;
    }

    .media-object-section--linkless-right {
        // flex: 1 0 70%;
        flex: 2 2 0px;
    }

    .media-object-section__icon {
        text-decoration: none;
    }

    &__thumbnail {
        &.media-object__thumbnail--circular {
            border-radius: 50%;
            max-width: 32px;
        }
    }
}

.media-object-event {
  width: 942px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C9E6E2;
  margin: 2rem auto;
  text-align: left;

  @include breakpoint(middling) {
    margin: 1.5rem auto;
  }
  @include breakpoint(medium) {
    align-items:inherit;
  }
}
