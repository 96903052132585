/*******************************************************************************
 * ACTIVITY-BOX.CSS
 *******************************************************************************
 */

 .activity-box {
    display: inline-block;
    text-align: center;
    font-weight: $global-weight-bold;
    color: $white;
    height: 1.5em;
    width: 1.5em;
    margin-right: .2em;
    background-color: $color-primary;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;
    outline: none;

    a {
        display: block;
        height: 100%;
        transition: background-color 0.25s ease-in-out;

        &:link, &:visited, &:hover {
            color: $white;
        }

    }

    &:hover {
        background-color: lighten($aubergine, 60%);
    }

    &__label {
      cursor: pointer;
      font-size: inherit;
    }

    &.activity-box--rounded {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        line-height: 2;
        margin-right: 0;
    }

    &.activity-box--white {
        cursor: default;
        background-color: white;
        border: none;

        &:hover {
            background-color: lighten($aubergine, 60%);
        }
    }

    &.activity-box--bordered {
        cursor: pointer;
        border: 1px solid $aubergine;
    }

    &.activity-box--bordered-green {
      cursor: pointer;
      background-color: white;
      border: 1px solid $qing;

      &:hover {
        background-color: lighten($qing, 60%);
      }
    }

    &.activity-box--bordered-aubergine {
      cursor: pointer;
      background-color: white;
      border: 1px solid $aubergine;

      &:hover {
        background-color: lighten($aubergine, 60%);
      }
    }

    &.activity-box--populated-active {
        cursor: pointer;
        background-color: $aubergine;
        transition: background-color 0.25s ease-in-out;

        a {
          text-decoration: none;

        }

        &:hover {
            background-color: lighten($aubergine, 60%);
        }

        &::before {
          font-family: chinacapable;
          content: '\e81f';
        }
    }

    &.activity-box--populated-active-green {
        cursor: pointer;
        background-color: $qing;
        transition: background-color 0.25s ease-in-out;

        a {
          text-decoration: none;

        }

        &:hover {
            background-color: lighten($qing, 60%);
        }
    }

    &.activity-box--populated-inactive {
        cursor: default;
        background-color: $aubergine;
        transition: background-color 0.25s ease-in-out;

        a {
            text-decoration: none;

          }

          &:hover {
              background-color: $aubergine;
          }
    }

    &.activity-box--populated-icon {
      &::before {
        font-family: chinacapable;
        content: '\e81f';
      }
    }

    &.activity-box--unpopulated-inactive {
      cursor: default;

      &:hover {
        background-color: $white;
      }
    }
}
