/*******************************************************************************
 * KT.CSS
 *******************************************************************************
 */

$kt-height: 420px;
$kt-height-small: 320px;
$kt-height-tiny: 182px;
$kt-border-bottom-right-radius: 30px;

.kt {
    &__hero {
        position: relative;
        margin-left: -.5rem;
        margin-right: -.5rem;
        margin-bottom: 0;
        opacity: 1;
        transition: background-color 0.25s ease-in-out;

        &.compat-object-fit {
          // Polyfill objectFit for IE11
          // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
          background-size: cover;
          background-position: center center;
          border-bottom-right-radius: $kt-border-bottom-right-radius;

          img {
            opacity: 0;
          }
        }

        &::before {
            content: '\e818';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-family: chinacapable;
            font-weight: $global-weight-bold;
            color: $white;
            text-align: center;
            cursor: pointer;
            z-index: 1;
            text-decoration: none;
            border: 1px solid $white;
            border-radius: 50%;
            padding-top: .5rem;
            margin: auto;
            width: 2.75rem;
            height: 2.75rem;
        }

        &:hover::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $aluminum;
            opacity: .4;
            border-bottom-right-radius: $kt-border-bottom-right-radius;
        }

        @include breakpoint(medium) {
          height: $kt-height;
          margin-left: 0;
          margin-right: 0;
        }

        &--small {
          @include breakpoint(medium) {
            height: $kt-height-small;
          }
        }

        &--tiny {
          @include breakpoint(medium) {
            height: $kt-height-tiny;
          }
        }
    }

    &__hero-videoless {
      position: relative;
      margin-left: -.5rem;
      margin-right: -.5rem;

      &.compat-object-fit {
        // Polyfill objectFit for IE11
        // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
        background-size: cover;
        background-position: center center;
        border-bottom-right-radius: $kt-border-bottom-right-radius;

        img {
          opacity: 0;
        }
      }

      .kt__thumbnail {
        @include breakpoint(medium) {
          height: $kt-height;
          border-bottom-right-radius: $kt-border-bottom-right-radius;
        }
      }

      @include breakpoint(medium) {
        height: $kt-height;
        margin-left: 0;
        margin-right: 0;
    }
    }

    &__progress-container {
        &.kt__progress-container--overlay-watched,
        &.kt__progress-container--overlay-attended  {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: auto;
            width: 100%;
            opacity: .8;
            color: $color-primary-darkened-30;

            &::after {
                position: absolute;
                bottom: 0;
                bottom: 30px;
                width: 100%;
                color: $white;
                text-align: center;
                font-weight: $global-weight-bold;
                opacity: 1;
                z-index: 1;
            }

            @include breakpoint(medium) {
              border-bottom-right-radius: $kt-border-bottom-right-radius;

              &::after {
                border-bottom-right-radius: $kt-border-bottom-right-radius;
              }
            }
        }

        &.kt__progress-container--overlay-watched {
            background-color: $color-primary-darkened-30;

            &::after {
                content: 'WATCHED';
            }
        }

        &.kt__progress-container--overlay-attended {
            background-color: $color-secondary;

            &::after {
                content: 'ATTENDED';
            }
        }
    }

    &__progress {
        position: absolute;
        bottom: 20px;
        height: .5em;
        width: 80%;
        background-color: $iron;
        margin: 0 10%;
        border-radius: 50px;

        .kt__progress-bar {
            position: absolute;
            bottom: 0;
            height: .5em;
            background-color: $color-primary-darkened-30;
            border-radius: 50px;
        }
    }

    &__item {
        color: $black;
        background-color: $white;
        margin-bottom: 2rem;

        a {
            color: $black;
            text-decoration: none;
        }

        @include breakpoint(medium) {
          height: $kt-height;
        }

        &--small {
          @include breakpoint(medium) {
            height: $kt-height-small;
          }
        }

        &--tiny {
          @include breakpoint(medium) {
            height: $kt-height-tiny;
          }
        }
    }

    &__body {
      position: relative;
      padding-bottom: 2rem;

      > *:last-child {
        margin-bottom: 0;
      }

      h3, h3 a {
        color: $aubergine;
        font-family: $body-font-family;
        font-size: 1.25rem;
        font-weight: $global-weight-bold;
      }

      h4, h4 a {
        margin-bottom: 0;
        font-family: $body-font-family;
        font-size: 1rem;
        font-weight: $global-weight-bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
      }

      @include breakpoint(medium) {
        padding-bottom: 1.2rem;
      }
    }

    &__flash {
        display: block;
        background-color: $graphite;
        color: $white;
        border-bottom-right-radius: 30px;
        padding: .75rem 1.7rem;
        margin-left: -1.7rem;
        margin-right: -.5rem;

        @include breakpoint(medium) {
          display: inline-block;
          height: 3em;
          margin-right: auto;
        }
    }

    &__link-block {
        position: relative;
        display: block;
        margin-left: -1rem;
        padding-left: 1rem;
        border-bottom: 1px solid grey;
        padding-top: 1em;
        padding-bottom: 1em;
        transition: background-color 0.25s ease-in-out;

        &:last-of-type {
          margin-bottom: 1em;
        }

        &::after {
            content: '\e80b';
            font-family: chinacapable;
            text-align: center;
            line-height: 1.9;
            color: $white;
            position: absolute;
            width: 2em;
            height: 2em;
            background-color: $teal-700;
            top: calc(50% - 1em);
            right: 1em;
            border-radius: 50%;
        }

        &:hover {
          background-color: $pewter;
        }

        &--watched,
        &--watched:hover {
            background-color: $snifter;
        }

        &--watched::before {
            content: 'watched';
            position: absolute;
            // right: 3.5em;
            // bottom: .9em;
            right: 1em;
            bottom: 0;
            color: $qing;
        }

        &--watched::after {
            content: '\e81f';
            background-color: $white;
            color: $qing;
        }
    }

    &__link.kt__link {
      color: $color-alert;
      text-decoration: underline;
    }

    &__date {
        margin-bottom: 0;
        color: $color-primary-darkened-30 !important;
    }

    &__duration {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $qing;
        color: $white;
        padding: .2em;
        font-size: .8rem; // <small> (<small> = 80%)

        @include breakpoint(medium) {
            font-size: .72rem; // .9 * <small> (<small> = 80%)
        }
    }

    &__duration-bottom {
      position: absolute;
      bottom: .5rem;
      width: 100%;
      padding-right: #{ 2 * 1.7 + .5 }rem;

      > p {
        margin-bottom: 0;
      }

      @include breakpoint(medium) {
        bottom: 1.2rem;
      }
    }

    &__topic-duration {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    &__duration-right {
      text-align: right;
    }

    &__thumbnail {
      width: 100%;

      @include breakpoint(medium) {
        position: absolute;
        object-fit: cover;
        height: $kt-height;
        width: auto;
        border-bottom-right-radius: $kt-border-bottom-right-radius;
      }

      &--small {
        @include breakpoint(medium) {
          height: $kt-height-small;
        }
      }

      &--tiny {
        @include breakpoint(medium) {
          height: $kt-height-tiny;
          width: 100%;
        }
      }
    }

    &__light {
      display: block;
      font-size: .8em;
      line-height: 1.5;
      font-weight: $global-weight-normal;
      color: $black !important;
    }
}
