
/*******************************************************************************
 * MODAL.CSS
 *******************************************************************************
 */

.modal {
    outline: none;
    padding: 2rem;

    &__title {
        color: $color-primary-darkened-30;
    }

    &__text {
        margin-bottom: .5rem;

        &:last-of-type {
          margin-bottom: 1rem;
        }
    }

    &__label {
        font-weight: $global-weight-bold;
        font-size: 1rem;
    }

    &__button-block {
        display: flex;
        justify-content: space-between;
    }

    &__button {
        text-transform: uppercase;
        font-weight: $global-weight-bold;
        float: right;
    }

    &__button-minor {
      margin-bottom: 0;
    }

    &--pathway {
        padding: 3.125rem;
    }
}
