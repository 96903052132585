/*******************************************************************************
 * PROGRESS-CIRCLE.CSS
 *******************************************************************************
 */

$progress-circle-size: 80;

.progress-circle-container {
    position: relative;
    display: flex;
    margin-right: 1em;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &::after {
        position: absolute;
        display: block;
        height: #{ $progress-circle-size * 2 }px;
        width: #{ $progress-circle-size * 2 }px;
        left: 50%;
        top: 50%;
        content: attr(data-total);
        margin-top: -#{ $progress-circle-size }px;
        margin-left: -#{ $progress-circle-size }px;
        border-radius: 100%;
        line-height: #{ $progress-circle-size * 2 }px;
        font-size: 2.75em;
        letter-spacing: -.05em;
        text-align: center;
    }
}

.progress-circle {
    &__meter {
        fill: none;
        stroke: $white;
    }

    &__value {
        fill: none;
        stroke: $mustard-900;
    }
}
