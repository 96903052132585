h1 {
    font-family: 'FF DIN Pro Medium';
    color: $teal-900;
    font-weight: bold;
    margin-bottom: .875rem;

    + h2 {
        margin-top: -1rem;
    }
}
