/*******************************************************************************
 * LOGO.CSS
 *******************************************************************************
 */

.logo {

    .logo--inline {
        margin-left: auto;
        margin-right: auto;
    }

    &.logo--top-center {
      display: inline-block;
      height: 50px;
      margin: .25rem;
   }

    &.logo--top {
        flex-grow: .5;
        z-index: 1;
        background-image: url("/assets/img/versioned/lotus_flower_new.svg");
        display: inline-block;
        background-repeat: no-repeat;
        width: 230px;
        height: 47px;
        margin: 0 0 0 1em;

        // @include breakpoint(large) {
        //     display: inline;
        //     left: 25px;
        //     background-size: 75px 90px;
        //     width: 75px;
        //     height: 90px;
        // }

        // @media screen and (min-width: 1230px) {
        //     background-image: url("/assets/img/versioned/logo_x2.png");
        //     background-size: 130px 156px;
        //     width: 130px;
        //     height: 156px;
        // }

        // @media screen and (min-width: 1416px) {
        //     background-size: 210px 252px;
        //     width: 210px;
        //     height: 252px;
        // }
    }

    &.logo--top-logged-out {
      height: 43px;
      margin: .7em 0 0 1em;
    }

    &.logo--kt {
        display: none;
        position: absolute;
        left: 0;
        top: 275px;
        z-index: 1;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5);
        background-color: $white;
        width: 210px;

        @media screen and (min-width: 1325px) {
          display: inline;
          left: 25px;
        }
    }

    &__body {
      padding: 3em 1em 0 1em;
    }

    &__body p {
      line-height: 1;
    }

    display: block;

    @include breakpoint(large) {
        display: none;
    }
}
