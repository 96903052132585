/*******************************************************************************
 * PREVNEXT.CSS
 *******************************************************************************
 */

 .prevnext {
     &__block {
         position: relative;
         background-color: $mercury;
     }

     &__flash {
         display: inline-block;
         color: $black;
         background-color: $color-tertiary;
         padding: .6em 1.2em;
     }

     &__title-block {
         text-align: left;
         border-bottom: 1px solid $white;
     }

     &__title {
         color: $black;
         text-decoration: none;
         padding: 1em;
     }

     &__nav-links {
         padding: 1.5em;
         padding-bottom: .5em;
         text-align: center;

        a {
            border-right: none !important;
        }
    }
 }


