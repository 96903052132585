/*******************************************************************************
 * MEDIA-OBJECT-LIST.CSS
 *******************************************************************************
 */

.media-object-list {
    border-top: 1px solid $graphite;
    padding-top: 1rem;

    &:last-child {
        border-bottom: 1px solid $graphite;
        margin-bottom: 2rem;
    }

    .media-object {
        margin-bottom: 0;

        img.media-object__thumbnail {
            max-width: 150px;
            margin-bottom: 1rem;
        }

        img.media-object__thumbnail--placeholder {
            max-height: 150px;
        }
    }

    .media-object-section--flexed {
        // Cope with IE 11 flex bug whereby the flex item is not 100% of remaining space.
        flex: 1 1 auto;
    }

    &.media-object-list--minor {
        border-top: 1px solid $iron;
        padding-top: 1.5rem;
        padding-bottom: .5rem;
    }

    &.media-object-list--minor:last-child {
        border-bottom: 1px solid $iron;
    }

    &.media-object-list--contributor {
        border-top: none;
        color: $color-primary-darkened-20;

        &:last-child {
            border-bottom: none;
        }
    }

    &.media-object-list--blog {
        border-top: none;

        &:last-child {
            border-bottom: 2px solid #cacaca;
        }
    }

}