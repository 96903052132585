/*******************************************************************************
 * COLOURBLOCKS.CSS
 *******************************************************************************
 */

.colourblocks {
    .colourblocks__row {
        width: 100vw;
    }

    .colourblocks__row:nth-child(odd) {
        .colourblock:nth-child(odd) {
            background-color: $tertiary-color;
        }

        .colourblock:nth-child(even) {
            background-color: $primary-color;
        }
    }

    .colourblocks__row:nth-child(even) {
        .colourblock:nth-child(odd) {
            background-color: $primary-color;
        }

        .colourblock:nth-child(even) {
            background-color: $tertiary-color;
        }
    }

    @include breakpoint(medium) {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
        margin-top: 2.5rem;

        .colourblocks__row {
            width: 100%;
        }
    }
}

.colourblock {
    text-align: center;
    padding: 1.7rem;

    &__link {
        display: block;
        text-decoration: none;
    }

    &__heading {
        display: inline-block;
        border-bottom: 1px solid $white;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
    }
}
