/*******************************************************************************
 * SECTION.CSS
 *******************************************************************************
 */

.section {
  &__pathways {
    background-color: $teal-900;
  }
}

.interactive {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  margin-top: 148px;

  &__bg-left {
    background-image: url("/assets/img/versioned/banner/interactive-left.jpg");
    background-position: right;
    background-repeat: repeat-x;
    flex-grow: 1;
  }

  &__bg-right {
    background-image: url("/assets/img/versioned/banner/interactive-right.jpg");
    background-position: left;
    background-repeat: repeat-x;
    flex-grow: 1;
  }

  &__block {
    text-align: center;
    padding: 28px 50px;
    width: 500px;
    margin: 0 auto;
  }

  &__block--white {
    background: $white;
  }

  &__block--green {
    background: $teal-400;
  }

  &__header {
    color: $teal-900;
    font-size: 2rem;
    letter-spacing: -0.03rem;
    font-weight: $global-weight-bold;
  }

  &__text {
    font-weight: $global-weight-bold;
    padding: 10px 0;
  }
}

@include breakpoint(medium) {
  .interactive {
    height: 225px;
    margin-top: 0px;
  }
  // Our default banner image
  .container::before {
    background-image: url("/assets/img/versioned/banner/china-capable-header-1.svg");
    background-position: center;
    height: 620px;
  }

  .section {
    background-color: $teal-400;

    .container::before {
      background-image: none;
    }

    &__home {
      main.wrapper {
        background: none;
        margin-bottom: 0;
      }
    }

    &__home-logged-in {
      .container::before {
        background: none;
        height: 0;
      }
    }

    &__profiles,
    &__knowledge-transfer,
    &__pathways,
    &__collections {
      .container::before {
        background-image: linear-gradient(
            to left,
            transparent,
            rgba(0, 0, 0, 0.4)
          ),
          url("/assets/img/versioned/banner/china-capable-header-1.jpg");
        height: 650px;
      }

      .columns {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
      }

      main.wrapper {
        margin-top: 8rem;
        padding-top: 0;

        .row--top {
          padding-top: 1rem;
        }
      }

      // &__home {
      //   .container::before {
      //     background-image: linear-gradient(
      //         to left,
      //         transparent,
      //         rgba(0, 0, 0, 0.4)
      //       ),
      //       url("/assets/img/versioned/banner/china-capable-header-1.jpg");
      //     background-position: center;
      //     height: 620px;
      //   }
      // }

      .summary {
        p:last-child,
        ul:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &__pathways {
      .container::before {
        background-image: linear-gradient(
            to left,
            transparent,
            rgba(0, 0, 0, 0.4)
          ),
          url("/assets/img/versioned/banner/china-capable-header-8.jpg");
        height: 600px;
      }
    }

    &__collections {
      .container::before {
        background-image: linear-gradient(
            to left,
            transparent,
            rgba(0, 0, 0, 0.4)
          ),
          url("/assets/img/versioned/banner/china-capable-header-8.jpg");
        height: 525px;
      }
    }

    &__profiles {
      main.wrapper {
        > .row--inverse {
          background-color: $oil;
        }
      }
    }

    &__knowledge-transfer {
      background-color: $aubergine;
      main.wrapper {
        > .row {
          h1 {
            color: $aubergine;
          }
        }
        > .row--inverse {
          background-color: $silver;
          color: $black;

          a {
            color: $oil;

            &:hover {
              color: $teal-700;
            }
          }

          .breadcrumbs {
            a {
              color: $black;
            }
            li:not(:last-child)::after {
              color: $black;
            }
          }
        }
      }

      .testimonial {
        &__heading {
          color: $aubergine;
        }
      }
    }

    &__pathways {
      background-color: $teal-900;

      main.wrapper {
        > .row--inverse {
          background-color: $teal-900;
          h1 {
            color: $white;
          }
        }
      }

      .section__hero {
        margin-top: -1rem;
        background-size: cover;
        background-position: center;

        &.section__hero--pathways {
          background-image: url("/assets/img/versioned/content/pathways_x1.jpg");

          /* 1.25 dpr */
          @media (-webkit-min-device-pixel-ratio: 1.25),
            (min-resolution: 120dpi) {
            background-image: url("/assets/img/versioned/content/pathways_x2.jpg");
          }
        }
      }
    }
  }
}
