/*******************************************************************************
 * EVENT-LIST.CSS
 *******************************************************************************
 */


.event-list {
    &__item:not(:last-of-type) .event-list__media-object {
        border-bottom: 1px solid $iron;
        padding-bottom: 1.2rem;
    }
}
