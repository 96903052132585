/*******************************************************************************
 * EVENTS-LIST.CSS
 *******************************************************************************
 */

.events-list-container {
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: -0.7rem;
}

.events-list {
  > :first-child {
    transition: background-color 0.25s ease-in-out;
    background-color: $teal-400;

    &:hover {
      background-color: darken($teal-400, 5%);
    }
  }

  > :last-child {
    transition: background-color 0.25s ease-in-out;
    background-color: darken($teal-400, 10%);

    &:hover {
      background-color: darken($teal-400, 15%);
    }
  }
}
