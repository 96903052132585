/*******************************************************************************
MAIN.CSS
*******************************************************************************/

main {
    flex: 1 0 auto;
    background-color: $white;
    padding-top: 1.5rem;
    // Fix a flex bug with IE11 / Win7, whereby main has too much whitespace at the bottom
    // https://stackoverflow.com/questions/35073868/space-added-to-child-of-a-flexbox-in-ie
    overflow: hidden;

    &.wrapper {
      margin-top: 5.25rem;
      margin-bottom: 4.25rem;
      padding-bottom: 0;

      &.wrapper--homepage {
        margin-top: 6rem;
      }
    }

    @include breakpoint(medium) {
        padding-top: 2rem;

        &.wrapper {
            margin-top: 5rem;

            &.wrapper--homepage {
              margin-top: 6rem;
            }
        }
    }
}
