/*******************************************************************************
 * TESTIMONIAL.CSS
 *******************************************************************************
 */

.testimonial {
    background-color: $silver;
    padding: 1rem;
    padding-top: 1.5rem;
    margin-bottom: 2.4rem;
    font-style: italic;

    &__thumbnail {
        border-radius: 50%;
    }

    &__icon {
        font-size: 8.875rem;
        color: darken($silver, 20%);

        &::before {
          margin: 0 1rem;
        }
    }

    &__figure {
        margin-top: 0;
        max-width: 8rem;
        margin-left: auto;
        margin-right: auto;
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: $global-weight-bold;
        margin-bottom: 1rem;
    }

    @include breakpoint(medium) {
        &__figure {
            max-width: 100%;
            margin-left: 0;
            margin-right: .5rem;
        }
    }
}
