/*******************************************************************************
 * NAV-LINKS.CSS
 *******************************************************************************
 */

.nav-links {
    text-align: right;
    margin-bottom: 2rem;

    a {
        text-decoration: none;
    }

    a:not(:last-child) {
        border-right: 1px solid $white;
    }

    &__previous::before {
        font-family: "chinacapable";
        content: '\e806';
        margin-right: .5rem;
    }

    &__next::after {
        font-family: "chinacapable";
        content: '\e80b';
        margin-left: .5rem;
    }
}
