/*******************************************************************************
 * ATTENDANCE-ITEM.CSS
 *******************************************************************************
 */

.attendance-item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $aluminum;
    padding-top: .75em;
    padding-bottom: .75em;

    &:last-child {
        border-bottom: 1px solid $aluminum;
    }

    &__title {
        flex: 1 1 0%;
    }

    &__activity-boxes > * {
        margin-left: 1.5rem;
    }

    &__activity-box {
        display: inline-flex;
        align-items: center;
    }
}

