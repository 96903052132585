/*******************************************************************************
 * COLUMNAR.CSS
 *******************************************************************************
 */

 .columnar {
    columns: 3;
    column-count: 3;
    column-gap: 1em;

    li {
        margin-bottom: .5em;
        line-height: 1.2;
    }
 }