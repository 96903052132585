/*******************************************************************************
 * EVENT-MEDIA-BLOCK.CSS
 *******************************************************************************
 */

.event-media-block {
    text-decoration: none;

    img {
        width: 130px!important;
        height: 130px;
        object-fit: cover;
        border-radius: 50%;
    }

    &__media-object {
        margin-bottom: .5rem;
    }

    &__section {
      &.compat-object-fit {
        // Polyfill objectFit for IE11
        // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        height: 130px;
        padding-right: 0;
        margin-right: 1rem;

        img {
          opacity: 0;
        }
      }
    }

    &__date {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 0;
    }

    &__heading {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.5;
    }

    &__title {
        font-style: italic;
    }

    &__body {
        font-size: 1rem;
        line-height: 1.2;
    }
}
