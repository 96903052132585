/*******************************************************************************
 * RECOMMENDED-AREAS.CSS
 *******************************************************************************
 */

 .recommended-areas {
   background-image: url("/assets/img/versioned/recommended.jpg");
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   height: 720px;
   position: relative;

   &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    opacity: .4;
   }

   &__title {
     position: absolute;
     left: 0;
     right: 0;
     font-size: 1.625rem;
     color: $white;
     text-align: center;
     padding: 5rem 0 3rem 0;
   }
   &__colophon {
     height: 1.3em;
     width: 1.3em;
     position: absolute;
     top: -.7em;
     left: calc(50% - 1.5em/2);
   }
}

@include breakpoint(middling) {
  .recommended-areas {
    height: 500px;
  }
}

