/*******************************************************************************
 * TOP-BAR.CSS (OVERRIDES)
 *******************************************************************************
 */

.top-bar {
    a {
        text-decoration: none;
    }

    &.top-bar--superior-menu {
        background-color: $white;

        ul {
            background-color: $white;
        }

        a {
            color: $charcoal;
            text-decoration: underline;
            flex-grow: 1;

            &::after {
                text-decoration: underline;
            }
        }

        .menu {
            flex-wrap: wrap;
            justify-content: center;
            width: 100vw;
        }

        .menu > li {
            &.has-form {
                width: 100%;
            }

            &:hover {
                background: none;

                a {
                    color: $color-primary-darkened-50;
                }
            }

            a {
                padding: 0 1.1rem;
                font-size: 0.95em;
            }
        }

        input {
            max-width: inherit;
        }

        @include breakpoint(medium) {
            input {
                max-width: 200px;
            }
        }
    }

    // Hack to ensure compiled CSS contains both .top-bar--superior-menu a:after
    // text-decoration declarations as we need them to make IE behave
    &.top-bar--superior-menu.top-bar--superior-menu {
        a {
            &::after {
                text-decoration: none;
            }
        }
    }

    .dropdown.menu.vertical > li.top-bar__icon-home > a {
        &::after {
            font-family: "chinacapable";
            content: '\e814';
            position: static;
            height: auto;
            margin-top: 0;
        }
    }

    li.top-bar__name {
        padding: 0 .5rem;
        color: $aluminum;
        margin-right: .85em;
    }

    li.top-bar__edit {
        display: none;
    }

    li.top-bar__icon-login {
        display: flex;
        display: -webkit-flex;
        margin: 1.1rem 0;

        > a {
            position: relative;
            padding: 0.7rem 1rem;
        }

        &--dashboard {
            background: $teal-700;
            padding: 12.5px 0;
            min-width: 180px;
            border-radius: 30px;
            margin-left: 20px;
            > a {
                color: $white;
                text-decoration: none;
                margin-right: 0;
                font-size: .9em;
            }
            &:hover {
                background: $teal-600!important;
                cursor: pointer;
                a {
                    color: $white!important;
                }
            }
        }
    }

    // li.top-bar__icon-login.top-bar__icon-login--logged-in > a:last-child {
    //     margin-right: 1.5rem;
    // }

    &__spacer {
        display: flex;
        display: -webkit-flex;
        line-height: 1;
        line-height: .75;
        color: $color-primary-darkened-30;
    }

    &.top-bar--main-menu {
        background-color: $teal-900;

        ul {
            background-color: $teal-900;
        }
    }

    .top-bar__interior {
        width: 100%;
        margin: auto;
    }

    .top-bar-right input {
        max-width: 100%;
    }

    .top-bar-right.menu > li {
        display: inherit;
        & > a {
            color: $white;
        }
    }

    .menu > li:hover {
        background-color: $teal-800;

        & > a {
            color: $white;
        }
    }

    .js-drilldown-back > a::before {
        font-family: "chinacapable";
        content: '\e806';
        border: 0;
    }

    .is-drilldown-submenu-parent > a::after {
        font-family: "chinacapable";
        content: '\e80b';
        border: 0;
    }

    .is-drilldown-submenu.is-active {
        background-color: $color-primary-darkened-20;

        a {
            color: $white;
        }
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a {
        &::after {
            border: 0;
        }
    }

    .is-dropdown-submenu > li:not(:last-child) > a {
        border-bottom: 1px solid $white;
    }

    .is-dropdown-submenu.first-sub {
        background: $teal-900!important;
    }

    .is-dropdown-submenu {
        background: $teal-800!important;
        min-width: 315px;
    }

    .is-dropdown-submenu > li > a {
        color: $white;
    }

    .is-dropdown-submenu-item {
        > a {
            padding-top: .875em!important;
            padding-bottom: .875em!important;
        }
    }

    .is-dropdown-submenu.first-sub > li {
        &:hover {
            background: $teal-800;
        }
    }

    .is-dropdown-submenu > li {
        &:hover {
            background: $teal-900;
        }
    }

    .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a {
        padding: .7rem 1.5rem .7rem 1rem;
        &::after {
            font-family: "chinacapable";
            content: '\e80b';
            border: 0;
            right: 25px;
            margin-top: -8px;
        }
    }

    @include breakpoint(medium) {
        &.top-bar--superior-menu {
            .menu {
                flex-wrap: nowrap;
                justify-content: inherit;
                width: auto;
            }

            .menu > li {
                &.has-form {
                    width: auto;
                }

                &:last-child {
                    margin-right: 1em;
                }
            }

            input {
                min-width: 200px;
                margin-right: 0;
                font-size: 0.95em;
            }
        }

        li.top-bar__edit {
            display: inherit;
        }

        .top-bar-right input {
            max-width: 150px;
            margin-right: 0;
        }

        .top-bar-right.menu > li {
            display: table-cell;
            padding: .52rem 0;
        }

        .top-bar__interior {
            max-width: none;
            margin-left: calc(50% - #{$wrapper-width} / 2);
            margin-right: calc(50% - #{$wrapper-width} / 2);
            display: flex;
            align-items: center;
        }
    }
}

/**
 * Target IE10/11 using a media query.
 * Note that we up specificity by one.
 * https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
    .top-bar.top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
        margin-top: -6px;
    }
}
