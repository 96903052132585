.profile-blocks-article {
    @include breakpoint(medium) {
        margin-right: 1.2rem;
    }
}

.profile-blocks {
    margin-right: -.5em;

    @include breakpoint(middling) {
        display: flex;
        flex-wrap: wrap;
    }

    @include breakpoint(medium) {
        margin-right: -1.7em;
    }
}

.profile-block-container {
    flex: 1 1 11.125em;
    padding-bottom: 1em;
    margin-right: .5em;
    margin-bottom: 1em;
    border-bottom: 1px solid #ccc;

    @include breakpoint(middling) {
        padding-bottom: 0;
        margin-bottom: 1.7em;
        border-bottom: none;
    }

    @include breakpoint(medium) {
        margin-right: 1.7em;
        max-width: 215px;
    }
}

.profile-block {
    display: flex;
    text-decoration: none;
    color: $black;
    height: 100%;
    transition: background-color .25s ease-in-out;

    /* https://jonathannicol.com/blog/2014/06/16/centre-crop-thumbnails-with-css/ */
    /* https://css-tricks.com/aspect-ratio-boxes/ */
    &__thumbnail-link {
        display: inline-block;
        position: relative;
        width: 70px;
        padding-top: 70px;
        height: 0;
        overflow: hidden;
        flex: 0 0 70px;
    }

    &__thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
    }

    &__content {
        flex: 1 1 auto;
        padding: .5em .5em 0 .5em;
    }

    &__heading {
        font-size: 1.1em;
        font-weight: bold;
    }

    &__body {
        color: $color-secondary;
        font-size: 1em;
        line-height: 1.2;
    }

    &__icons {
        display: none;
        position: absolute;
        bottom: .25em;
        left: .25em;

        &:not(:last-child) {
            margin-right: .25em;
        }
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &--linked {
        &:hover {
            background-color: #e6e6e6;
        }
    }

    @include breakpoint(middling) {
        flex-direction: column;

        &__thumbnail-link {
            width: 100%;
            padding-top: 100%;
            flex: none;
        }

        &__thumbnail {
            left: 50%;
            top: 50%;
            height: 100%;
            transform: translate(-50%, -50%);
        }

        &__content {
            padding: .5em;
            border: 1px solid $iron;
        }

        &__icons {
            display: flex;
        }
    }
}
