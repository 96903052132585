/*******************************************************************************
 * KT.CSS
 *******************************************************************************
 */

$kt-home-height: 172px;
$kt-home-width: 248px;
$kt-border-bottom-right-radius: 30px;

.kt-home {
  &__container {
    width: 1024px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__hero-videoless {
    position: relative;
    height: $kt-home-height !important;
    width: 100%;

    &.compat-object-fit {
      // Polyfill objectFit for IE11
      // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
      background-size: cover;
      background-position: center center;
      border-bottom-right-radius: $kt-border-bottom-right-radius;

      img {
        opacity: 0;
      }
    }

    .kt-home__thumbnail {
      height: $kt-home-height !important;
      width: 100%;
      border-bottom-right-radius: $kt-border-bottom-right-radius;
    }
  }

  &__details {
    padding: 2.2rem 1.2rem 1.8rem 1.2rem;
  }

  &__title {
    display: block;
  }

  &__item {
      width: 100%;
      background: $white;
      margin-bottom: .75rem;
      transition: opacity .25s ease-in-out;

      &:hover {
        opacity: .9;
      }
  }

  &__body {
    position: relative;

    > *:last-child {
      margin-bottom: 0;
    }

    h3, h3 a {
      color: $aubergine;
      font-family: $body-font-family;
      font-size: 1.25rem;
      font-weight: $global-weight-bold;
    }

    h4, h4 a {
      margin-bottom: 0;
      font-family: $body-font-family;
      font-size: 1rem;
      font-weight: $global-weight-bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 90%;
    }
  }


  @include breakpoint(middling) {
    &__item {
      width: $kt-home-width;
    }
    &__hero-videoless {
      width: $kt-home-width;
      .kt-home__thumbnail {
        width: $kt-home-width;
      }
    }
  }

  &__link-block {
    display: block;
    position: absolute;
    left: 1em;
    width: 2.5em;
    height: 2.5em;
    top: calc(100% - 1.3em);
    margin-bottom: 0;
    transition: background-color 0.25s ease-in-out;
    text-decoration: none;
    z-index: 1;

    &::after {
      content: '\e80b';
      display:block;
      font-family: chinacapable;
      text-align: center;
      line-height: 2.4;
      color: $white;
      width: 2.5em;
      height: 2.5em;
      left: 0;
      background-color: $teal-700;
      border-radius: 50%;

      &:hover {
        background-color: $teal-600;
      }
    }
  }

  &__thumbnail {
    position: absolute;
    object-fit: cover;
    height: $kt-home-height !important;
    width: $kt-home-width;
    border-bottom-right-radius: $kt-border-bottom-right-radius;
  }
}
