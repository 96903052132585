/*******************************************************************************
 * FLEX.CSS
 *******************************************************************************
 */

 .flex {
    display: flex;

    &.flex--between {
        align-items: center;
        justify-content: space-between;
    }

    &.flex--column-between {
        flex-direction: column;
        justify-content: space-between;
    }

    &.flex--start {
      align-items: flex-start;
    }

    &.flex--end {
        align-items: flex-end;
    }

    &.flex--stretch {
        align-items: stretch;
    }
}
