/*******************************************************************************
 * QUIZ.CSS
 *******************************************************************************
 */

.quiz {
    &__question {
      ol.alpha-list {
        margin-left: 2.25rem;

        li {
          list-style-type: lower-alpha;
        }
      }
    }
}
