/*******************************************************************************
 * PATHWAY.CSS
 *******************************************************************************
 */

$pathway-height: 420px;
$pathway-height-minor: 260px;
$pathway-border-bottom-right-radius: 30px;

.pathway {
    &__hero {
      height: $pathway-height;
      position: relative;
      margin-left: -.5rem;
      margin-right: -.5rem;

      &.compat-object-fit {
        // Polyfill objectFit for IE11
        // https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
        background-size: cover;
        background-position: center center;
        border-bottom-right-radius: $kt-border-bottom-right-radius;

        img {
          opacity: 0;
        }
      }

      .pathway__thumbnail {
        width: 100%;
        height: $pathway-height;
        border-bottom-right-radius: $pathway-border-bottom-right-radius;
      }

      @include breakpoint(medium) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__item {
        height: auto;
        color: $black;
        background-color: $white;
        margin-bottom: 2rem;

        a {
            color: $black;
            text-decoration: none;
        }

        &--highlighted {
          background-color: $teal-400;
        }

        &.pathway__item--minor {
          margin-bottom: 1rem;
        }

        @include breakpoint(medium) {
          height: $pathway-height;

          &.pathway__item--minor {
            height: $pathway-height-minor;

            .pathway__hero {
              height: $pathway-height-minor;
            }

            .pathway__thumbnail {
              height: $pathway-height-minor;
            }
          }
        }
    }

    &__body {
      position: relative;
      text-align: left;

      > *:last-child {
        margin-bottom: 0;
      }

      h3, h3 a {
        color: $teal-900;
        font-family: $body-font-family;
        font-size: 1.25rem;
        font-weight: $global-weight-bold;
      }

      h3.pathway__minor-heading {
        position: absolute;
        top: -75px;
        width: 265px;

        @include breakpoint(medium) {
          top: 1.7rem;
          left: -295px;
        }
      }

      .pathway__minor-link {
        color: $white;
        text-shadow: 2px 2px 0.5em $steel;
      }

      h4, h4 a {
        margin-bottom: 0;
        font-family: $body-font-family;
        font-size: 1rem;
        font-weight: $global-weight-bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
      }
    }

    &__thumbnail {
      position: absolute;
      left: 0;
      object-fit: cover;
      height: $pathway-height;
      border-bottom-right-radius: $pathway-border-bottom-right-radius;
    }

    &__progress {
        height: .5em;
        background-color: $iron;
        border-radius: 50px;
        max-width: 400px;

        .pathway__progress-bar {
            height: .5em;
            background-color: $color-primary;
            border-radius: 50px;
        }

        &--light {
          background-color: $white;
        }
    }

    &__duration-bottom {
      width: 100%;
      text-align: center;

      >p {
        margin-bottom: 0;
      }

      @include breakpoint(medium) {
          position: absolute;
          bottom: 1.2rem;
          padding-right: #{ 2 * 1.7 + .5 }rem;
          text-align: left;
      }
    }

    &__duration-bottom-buttons {
      display: block;
      text-align: center;

      @include breakpoint(medium) {
        display: flex;
        align-items: baseline;
        text-align: left;
      }
    }

    &__duration-bottom-link {
      @include breakpoint(medium) {
        margin-left: 2.4rem;
      }
    }

    &__block-footer {
      font-size: 0.85rem;
    }
}
