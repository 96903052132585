/*******************************************************************************
 * DARK_CONTAINER.CSS
 *******************************************************************************
 */

 .dark_container {
    background-color: $iron;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}
