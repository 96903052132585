/*******************************************************************************
 * FORUM-ICON.CSS
 *******************************************************************************
 */

.forum-icon {
    color: $white;
    height: 100%;
    min-width: 2.32125rem;
    text-align: center;
    font-size: .85em;
    font-weight: $global-weight-bold;
    padding: .5em;
    margin-right: .5em;
    transition: background-color 0.25s ease-in-out;

    &__link {
        display: inline-block;
        text-decoration: none;
    }

    &--cop {
        background-color: #770432;

        &.forum-icon--linked:hover {
            background-color: darken(#770432, 10%);
        }
    }
    &--kt {
        background-color: #c11533;

        &.forum-icon--linked:hover {
            background-color: darken(#c11533, 10%);
        }
    }
    &--mc {
        background-color: #2baea3;

        &.forum-icon--linked:hover {
            background-color: darken(#2baea3, 10%);
        }
    }
    &--caf {
        background-color: $color-tertiary;

        &.forum-icon--linked:hover {
            background-color: darken($color-tertiary, 10%);
        }
    }
    &--profile {
        margin-right: .25em;
    }

    &--green {
        display: inline-block;
        height: auto;
        color: $qing;
        background-color: #e3f4f1;
    }
}