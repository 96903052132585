/*******************************************************************************
 * BLOG.CSS
 *******************************************************************************
 */

.blog {
    &__title {
        font-weight: $global-weight-bold;
        font-size: 1.75rem;

        a {
            text-decoration: none;
        }
    }

    &__byline {
        color: $aluminum;
        margin-bottom: 1rem;
    }
}