/*******************************************************************************
 * TITLE-BAR.CSS (OVERRIDES)
 *******************************************************************************
 */

.title-bar {
    justify-content: flex-start;

    @include breakpoint(medium) {
        justify-content: space-between;
    }
}

