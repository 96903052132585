/*******************************************************************************
 * BREADCRUMBS.CSS
 *******************************************************************************
 */

.breadcrumbs {

    // Truncate overly long breadcrumbs
    a {
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (min-width: 40em) {
        a {
            max-width: 500px;
        }
    }

    li:not(:last-child)::after {
        content: ">";
        color: $black;
        margin: 0 0.375rem;
    }

}
