/*******************************************************************************
 * SIDEBOX.CSS
 *******************************************************************************
 */

.sidebox {
    padding: 0 1rem 1rem;
    margin-bottom: 1rem;

    a {
        color: $black;
    }

    h2 {
        padding: .5rem 1rem;
        margin: 0 -1rem 1rem -1rem;
        font-size: 1rem;
        line-height: 1.5;
    }

    ul {
        font-size: .857rem;
    }

    > .menu > li > a,
    > .menu > li > .active {
        padding-left: 0;
    }

    &__headings h2 {
        background-color: lighten($primary-color, 20%);
        color: $black;
        font-size: .857rem;
        font-family: $body-font-family;
        font-weight: 400;
        margin-bottom: 0;
        padding-top: 0;
        line-height: 1.25;
    }

    &--section, &--related {
        h2 {
            color: $white;
            background-color: $mustard-900;
            border-bottom-right-radius: 30px;
        }
        background-color: $mustard-800;
    }

    &--outline {
        background-color: $teal-500;

        > h2 {
            background-color: $teal-900;
            color: $white;
            margin-bottom: .5rem;
        }
        .sidebox__headings h2 {
            background-color: $teal-500;
            border-radius: 0;
            color: $black;
        }
    }
}
