/*******************************************************************************
 * COLOR.CSS
 *******************************************************************************
 */

.colors {
    display: flex;
    flex-wrap: wrap;
}

.colors-group-title {
    padding-top: 20px;
    color: $qing;
    margin-top: 1.5rem;
}

.color {
    width: 105px;
    height: 105px;
    margin: 10px 10px 10px 0;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    font-weight: bold;
    color: $white;

    &--text-light {
        color: $black;
    }

    &--white {
        background: $white;
    }

    &--ghost {
        background: $ghost;
    }

    &--snow {
        background: $snow;
    }

    &--vapor {
        background: $vapor;
    }

    &--white-smoke {
        background: $white-smoke;
    }

    &--pewter {
        background: $pewter;
    }

    &--silver {
        background: $silver;
    }

    &--smoke {
        background: $smoke;
    }
    &--mercury {
        background: $mercury;
    }

    &--gainsboro {
        background: $gainsboro;
    }

    &--iron {
        background: $iron;
    }

    &--base {
        background: $base;
    }

    &--aluminum {
        background: $aluminum;
    }

    &--jumbo {
        background: $jumbo;
    }

    &--monsoon {
        background: $monsoon;
    }

    &--graphite {
        background: $graphite;
    }

    &--steel {
        background: $steel;
    }

    &--charcoal {
        background: $charcoal;
    }

    &--tuatara {
        background: $tuatara;
    }

    &--oil {
        background: $oil;
    }

    &--jet {
        background: $jet;
    }

    &--black {
        background: $black;
    }

    &--ochre {
        background: $ochre;
    }

    &--yellow {
        background: $yellow;
    }

    &--maroon {
        background: $maroon;
    }

    &--aubergine {
        background: $aubergine;
    }

    &--qing {
        background: $qing;
    }

    &--snifter {
        background: $snifter;
    }

    &--color-primary {
        background: $color-primary;
    }

    &--color-secondary {
        background: $color-secondary;
    }

    &--color-tertiary {
        background: $color-tertiary;
    }

    &--color-success {
        background: $color-success;
    }

    &--color-warning {
        background: $color-warning;
    }

    &--color-alert {
        background: $color-alert;
    }

    &--teal-900 {
        background: $teal-900;
    }

    &--teal-800 {
        background: $teal-800;
    }

    &--teal-700 {
        background: $teal-700;
    }

    &--teal-600 {
        background: $teal-600;
    }

    &--teal-500 {
        background: $teal-500;
    }

    &--teal-400 {
        background: $teal-400;
    }

    &--mustard-900 {
        background: $mustard-900;
    }

    &--mustard-800 {
        background: $mustard-800;
    }



    &--color-primary-lightened-30 {
        background: lighten($color-primary, 30%);
    }

    &--color-primary-darkened-10 {
        background: darken($color-primary, 10%);
    }

    &--color-primary-darkened-15 {
        background: darken($color-primary, 15%);
    }

    &--color-primary-darkened-20 {
        background: darken($color-primary, 20%);
    }

    &--color-primary-darkened-25 {
        background: darken($color-primary, 25%);
    }

    &--color-primary-darkened-30 {
        background: darken($color-primary, 30%);
    }

    &--color-primary-darkened-40 {
        background: darken($color-primary, 40%);
    }

    &--color-primary-darkened-50 {
        background: darken($color-primary, 50%);
    }

    &--color-alert-inverse {
        background: lighten($color-tertiary, 40%);
    }

    &--color-alert-lightened-10 {
        background: lighten($color-alert, 10%);
    }

    &--color-alert-darkened-10 {
        background: darken($color-alert, 10%);
    }

    &--color-alert-darkened-30 {
        background: darken($color-alert, 30%);
    }
}
.color-hex {
    font-size: .875rem;
    padding-top: 15px;
    display: block;
}
