/*******************************************************************************
 * CONTAINER.CSS
 *******************************************************************************
 */

.container {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

@include breakpoint(medium) {
    // We define the actual banner image and height in _section.scss
    .container::before {
        position: absolute;
        content: '';
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }
}
