/*******************************************************************************
 * DASHBOARD-BLOCK.CSS
 *******************************************************************************
 */

 .dashboard-blocks {
  display: flex;
  margin-bottom: .5rem;
}

.dashboard-block {
  flex: 1;
  padding: 1.2rem 0;
  color: $white;
  text-align: center;

  p {
    color: $white;
  }

  &:nth-child(2) {
    opacity: .95;
  }

  &:nth-child(3) {
    opacity: .90;
  }

  &__hero {
    line-height: 1.2;
    font-size: 2.625rem;

    &.dashboard-block__hero--minor {
      font-size: 2.25rem;
    }
  }

  &__text {
    font-size: 1rem;
    margin-left: -.5rem;
  }

  &.dashboard-block--double {
    flex: 2;
  }

  &.dashboard-block--aubergine {
    background-color: $aubergine;
  }

  &.dashboard-block--teal {
    background-color: $teal-900;
  }
}

.certificate-item {
    background-color: #fff;
    padding: 2rem;
    text-align: left;
    margin-bottom: .6rem;

    h2 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
}

@include breakpoint(medium) {
  .certificate-list {
    display: flex;
    justify-content: flex-start;
  }
  .certificate-item {
    width: 50%;
    flex: 1;

    &--double {
      flex: 2;
    }

    &--left-margin:nth-child(even) {
      margin-left: .6rem;
    }
  }
}
