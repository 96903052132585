/*******************************************************************************
 * MEDIA-BANNER.CSS
 *******************************************************************************
 */

 @include breakpoint(medium) {
    .media-object-event {
        align-items: flex-start;
    }
 }

.media-banner .media-banner__panel.stack-for-small {
    .media-banner__panel-body {
        padding: 1rem;
    }

    @media(min-width: 400px) {
        .media-banner__panel-body {
            padding-left: 0;
        }
    }

    @media(min-width: 1024px) {
        .media-banner__panel-body {
            padding-left: 1rem;
        }
    }
}

.media-banner {
    margin-left: $global-margin-halved;
    margin-right: $global-margin-halved;
    margin-bottom: 2rem;

    a {
        text-decoration: none;
        width: 100%;
    }

    &__panel {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: left;

        &:nth-child(1) {
            background-color: #75c7b9;
        }

        &:nth-child(2) {
            background-color: #90d2c6;
        }

        &:nth-child(3) {
            background-color: #c7e8e2;
        }

        &-image {
            overflow: hidden;

            img {
                width: 512px;
            }

            &:first-child {
                padding-right: 0;
            }
        }

        &-section {
          padding: 1rem;
        }

        &-title {
            font-weight: bold;
            font-size: 1.25rem;
            padding-bottom: 1rem;
        }

        &-body {
            padding-top: .5em;

            ul {
                margin: 0;
                margin-bottom: 1em;
            }

            ul > li {
                list-style: none;
                margin-bottom: .25em;
            }

            ul > li a {
                color: $white;
            }
        }
    }

    @include breakpoint(middling) {
        &__panel {
            &-link {
                display: flex;
            }

            &-image {
                img {
                    max-width: 150px;
                }
            }

            &-section {
              padding: 1.75rem;
            }
        }
    }

    @include breakpoint(medium) {
        margin-left: -1.2rem !important;
        margin-right: -1.2rem !important;

        &__panel {
            display: block;
            text-align: center;

            &-link {
                display: block;
            }

            &-image {
                width: 50%;
                img {
                    max-width: 100%;
                }

                &:first-child {
                    overflow: hidden;
                }
            }

            &-section {
              width: 50%;
            }

            &-title-container {
                display: block;
                margin-top: -1.75rem;
                margin-bottom: 1rem;
            }
        }
    }
}
