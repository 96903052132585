/*******************************************************************************
 * SUMMARY.CSS
 *******************************************************************************
 */

.summary {
    margin: 1rem 0 1.5rem;
    font-size: 1.25rem;

    .summary__minor {
        border-bottom: none !important;
        font-size: 1rem;
    }

    ul {
        margin-left: auto;
    }

    li {
        margin-left: 1.25rem;
    }

    p:last-child,
    ul:last-child {
        border-bottom: 1px solid $graphite;
        padding-bottom: 1rem;
        margin-bottom: 0;
    }
    &--no-border-bottom {
        p:last-child,
        ul:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: inherit;
        }
    }
}