/*******************************************************************************
 * DASHBOARD-TABLE.CSS
 *******************************************************************************
 */

.dashboard-table {
  th:nth-child(1) {
    width: 66.666%;
  }

  th:nth-child(2) {
    opacity: 0.90;
  }

  th:nth-child(3) {
    opacity: 0.90;
  }

  tbody {
    border: none;

    tr:nth-child(even) {
      background-color: $white;
    }
  }

  &.dashboard-table--teal th {
      background-color: $teal-900;
      color: $white;
  }

  &.dashboard-table--teal tr {
    border-bottom: 1px solid $teal-900;
  }

  &.dashboard-table--aubergine th {
      background-color: $aubergine;
      color: $white;
  }

  &.dashboard-table--aubergine tr {
    border-bottom: 1px solid $aubergine;
  }

  @include breakpoint(medium) {
    th, td {
      padding: 1em 1.5em;
    }
  }
}
