/*******************************************************************************
HTML.CSS
*******************************************************************************/

html {
    // A mechanism to graceully reveal the page once webfonts have loaded,
    // a la AMP. Only useful if the load of the webfonts is reasonably fast
    // (< 2 seconds).

    &.wf-loading {
        // Setting opacity:0 here means that it won't get set if no JavaScript
        // support of JavaScript is broken.
        opacity: 0;
    }

    &.wf-active,
    &.wf-inactive {
        opacity: 1;
        transition: opacity .25s ease-in-out;
    }
}
