/*******************************************************************************
 * LINK.CSS
 *******************************************************************************
 */

.link {
    &__rollover {
        color: $color-alert;
        text-decoration: underline !important;

        &:hover {
            color: $color-alert-darkened-30;
            text-decoration: underline !important;
        }
    }
}
