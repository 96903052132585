/*******************************************************************************
 * TAGS.CSS
 *******************************************************************************
 */

.tags {
    &--title {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.6rem;
    }
    &.tags--bottom {
        border-top: 1px solid $graphite;
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    &.tags--bottom-search {
        padding-top: 1rem;
    }

    &__list {
        display: inline;

        li {
            display: inline-block;

            a {
                color: $alert-color;
            }

            &:after {
                content: ',';
            }

            &:last-child:after {
                content: '';
            }

        }

        &.tags__list--buttons li {
            &:after {
                content: '';
            }
        }
    }

    &__label {
        color: $alert-color;
    }
}
