/*******************************************************************************
 * RESULT.CSS
 *******************************************************************************
 */

.result {

    &__continue {
        margin-bottom: 2rem;

        a {
            color: $alert-color;
        }
    }
}
