/*******************************************************************************
 * EVENT-DETAILS.CSS
 *******************************************************************************
 */

.event-details {
    > p {
        margin-bottom: 0;
    }

    margin-bottom: 1rem;
}
