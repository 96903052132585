.display_event {
  display: flex;
  flex-wrap: wrap;
  background-color: #c9e6e2;
  margin: 1.5rem auto;
  max-width: 942px;

  &__section {
    text-align: left;
    width: 100%;

    @include breakpoint(500px) {
      width: 50%;
      flex-wrap: nowrap;
    }
  }

  &__section:first-child {
    padding: 0;
  }

  &__section + .display_event__section {
    padding: 1.75rem;
  }

  &__h3 {
  }

  &__h4 {
  }
}
