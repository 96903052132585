/*******************************************************************************
 * PAGINATION.CSS (OVERRIDES)
 *******************************************************************************
 */

.pagination {
  &.pagination--inverse {
    li.current {
      background: $color-primary;
    }

    li {
      background: $white;
    }
  }
}
