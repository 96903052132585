/*******************************************************************************
 * INTRODUCTION.CSS
 *******************************************************************************
 */

.introduction {
  a[href=""] {
    color: red;
  }

  &__register {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    width: 886px;
    padding-bottom: 2rem;
  }

  &__register-block {
    display: flex;
    height: 250px;
    align-items: center;
    flex-direction: column;
    background-color: $white;
    font-size: 1rem;

    &.introduction__register-block--outer {
      width: 142px;
      display: none;
    }

    &.introduction__register-block--inner {
      width: 175px;
      display: none;
    }

    &.introduction__register-block--middle {
      background-color: #c9e6e2;
      width: 100% !important;
      padding: 1rem;
    }

    &.introduction__register-block--left-outer {
      background-image: url("/assets/img/versioned/banner/introduction_left_outer@2x.png");
      background-position: center;
      background-size: cover;
    }

    &.introduction__register-block--left-inner {
      background-image: url("/assets/img/versioned/banner/introduction_left_inner@2x.png");
      background-position: center;
      background-size: cover;
    }

    &.introduction__register-block--right-inner {
      background-image: url("/assets/img/versioned/banner/introduction_right_inner@2x.png");
      background-position: center;
      background-size: cover;
    }

    &.introduction__register-block--right-outer {
      background-image: url("/assets/img/versioned/banner/introduction_right_outer@2x.png");
      background-position: center;
      background-size: cover;
    }

    @include breakpoint(medium) {
      &.introduction__register-block--middle {
        background-color: $white;
      }
    }

    @include breakpoint(620px) {
      &.introduction__register-block--middle {
        width: 252px !important;
      }
      &.introduction__register-block--inner {
        display: flex;
      }
    }

    @include breakpoint(890px) {
      &.introduction__register-block--outer{
        display: flex;
      }
    }
  }


  &__text {
    font-size: 1.625rem;
    color: $teal-900;
    text-align: center;
    font-weight: bold;
    width: 100%;
    p {
      margin: 0 0 1rem 0;
    }
  }

  &__heading {
    font-size: 1.5rem;
    letter-spacing: -0.03rem;
    color: $color-primary-darkened-30;
    font-weight: $global-weight-bold;
  }

  &__button-group {
    margin-bottom: 1rem;
    text-align: center;
    width: 663px;
    background-color: $teal-900;
    padding: 1rem;
    justify-content: space-between;
  }

  &__button-group .button {
    font-weight: $global-weight-bold;
    margin-bottom: 0;
    background: none;
    border: 1px solid #fff;
    font-size: 1rem;
    border: 2px solid #fcfafa;
  }

  &__button-container {
    &.introduction__button-container--left {
      text-align: center;
    }

    &.introduction__button-container--right {
      text-align: center;
    }
  }

  &__button-group {
    .button.button--cta-major {
      @include button-style(
        $color-primary-darkened-30,
        $color-primary-darkened-40,
        $white
      );
      min-width: 10em;
      padding-left: 2em;
      padding-right: 2em;
    }

    .button.button--cta-minor {
      @include button-style($primary-color, $color-primary-darkened-20, $white);
      min-width: 10em;
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  &__button-spacer {
    font-size: 1.5rem;
    line-height: 2.2;
    color: $aluminum;
  }

  &__lead {
    font-size: 1.3em;
  }

  &__subheader {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
  }

  &__image {
    border-bottom-right-radius: 30px;
    object-fit: cover;

    @include breakpoint(800px) {
      height: 229px;
    }

    @include breakpoint(medium) {
      width: 334px;
    }
  }

  &__subheading {
    display: inline-block;
    text-align: center;
    border-bottom: 1px solid $tertiary-color;
    padding-bottom: 0.5em;
    margin-bottom: 0;
  }

  &__subsubheading {
    padding: 2.2rem 1.2rem 1.8rem 1.875rem;
    font-weight: bold;
    font-size: 1rem;
  }

  &__loggedin-tiles {
    margin-bottom: 1rem;
  }

  &__inverted {
    background-color: $gainsboro;
    padding: 2rem 1.7rem 1.2rem 1.7rem;
  }

  @include breakpoint(middling) {
    &__text {
      p {
        line-height: 1.2;
        margin: 0 auto 2rem auto;
      }
    }
    &__button-group {
      padding: 2.5rem 5rem;
    }

    &__button-group .button {
      width: 220px;
      padding: 1.095em 1.95em;
      border-radius: 39px;
    }
  }

  @include breakpoint(medium) {
    &__heading {
      font-size: 1.6rem;
      text-align: center;
    }

    &__loggedin-tiles {
      padding-bottom: 2rem;
      margin-bottom: 3.5rem;
      border-bottom: 3px solid $medium-gray;
    }

    &__button-container {
      &.introduction__button-container--left {
        text-align: right;
      }

      &.introduction__button-container--right {
        text-align: left;
      }
    }

    &__inverted {
      margin-left: -1.2rem !important;
      margin-right: -1.2rem !important;
    }
  }
}
