/*******************************************************************************
 * VIDEO.CSS
 *******************************************************************************
 */

.video-fit-width {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video {
  &.video--vimeo {
    padding-bottom: 0;
    margin-bottom: 1.5rem;

    .video__list {
        dt,
        dd {
            float: left;
            margin: 0;
        }

        dt {
            clear:both;
            margin-right: 1em;
            width: 2rem;
        }
    }

    .timecode {
        color: $color-alert;
    }
  }

  &.video--fullwidth {
    margin-left: 1.7rem;
    margin-right: 1.7rem;
   }
}

