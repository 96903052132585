/*******************************************************************************
 * BUTTON.CSS
 *******************************************************************************
 */

// Duplicate foundation's mixin here, as the original has a hard-coded $lightness: -50%
// which is too much for our purposes.
@mixin button-hollow-style($color: $primary-color) {
  $color-hover: scale-color($color, $lightness: -20%);

  border: 1px solid $color;
  color: $color;

  &:hover,
  &:focus {
    border-color: $color-hover;
    color: $color-hover;
  }
}

.button {
  text-decoration: none;
  font-size: 0.875em;
  padding: 0.85em 1.95em;
  outline: none;
  line-height: 1.25;

  &.alert {
    &:hover {
      background-color: $color-alert-lighten-10;
    }
  }

  &.inverted {
    @include button-style($color-inverse, $smoke, $color-primary-darkened-40);
  }

  &.button--round {
    border-radius: 1.425em;
  }

  &.button--inverse-hollow-round {
    @include button-hollow-style($white);
    border-radius: 1.425em;
  }

  &.button--inverse-colored-hollow-round {
    @include button-hollow-style($teal-800);
    border-radius: 1.425em;
    border-width: 2px;
    font-weight: $global-weight-bold;
  }

  &.button--register {
    background-color: $color-primary-darkened-20;
    font-size: 1em;

    &:focus,
    &:hover {
      background-color: $color-primary-darkened-30;
    }
  }

  &.button--inverse {
    color: $white;
    font-weight: $global-weight-bold;
  }

  &.button--minor {
    padding: 0.6em;
    border-color: $iron;
    color: $color-primary-darkened-30;

    &:hover {
      border-color: $base;
    }

    &.button--unclicked {
      border-color: $aluminum;
      color: $aluminum;

      &:hover {
        border-color: $color-aluminum-darkened-30;
      }
    }
  }

  &.button--green {
    background-color: $teal-700;
    color: $white;

    &:hover {
      background-color: $teal-600;
      color: $white;
    }
  }

  &.button--separator-top {
    border-top: 1px solid $snifter;
  }

  &.button--full {
    margin: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.9;
  }

  &.button--full-width {
    margin: 0;
    width: 100%;
  }

  &.button--more {
    position: relative;
    outline: none;
    width: 100%;
    border-radius: 1.5em;
    color: $black;
    background-color: $gainsboro;

    &:hover {
      background-color: darken($gainsboro, 10%);
    }
  }

  &.button--triangle-below {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: calc(50% - 1rem);
      transition: color 0.25s ease-out, color 0.25s ease-out;
      color: $color-primary-darkened-30;
      width: 0;
      height: 0;
      bottom: -1rem;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-top: 1rem solid currentColor;
    }

    &:hover:after {
      color: $color-primary-darkened-40;
    }
  }
}
